<template>
  <div class="watch_film_box">
    <van-nav-bar title="" left-arrow @click-left="$router.go(-1)" fixed :border='false' />
    <div class="player-container film">
      <vue-core-video-player :src="getImage(this.$route.query.item)" :autoplay="true"></vue-core-video-player>
    </div>
  </div>
</template>

<script>
import { getImage } from '@/utils/upload'

  export default {
    name: "watch_film",
    created() {
      window.scrollTo(0, 0)
    },
    data() {
      return {}
    },
    methods: {
      getImage
    }
  }
</script>

<style scoped lang="less">
  .box {
  }

  .watch_film_box {
    min-height: 100vh;
    position: relative;

    .film {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translate(0, -50%);
    }
  }
</style>
