<template>
  <div class="box" @click="box">
    <van-nav-bar title="個人檔案" left-arrow @click-left="$router.go(-1)" fixed :border="false">
      <template #right>
        <div class="right_img" @click="share">
          <img src="~@/assets/img/person01.png" />
        </div>
      </template>
    </van-nav-bar>
    <div class="heard">
      <div class="heardflex">
        <div class="header_border">
          <img class="header_img" :src="getImage(tags.photo1)" />
        </div>
        <div class="flex_right">
          <div class="name">{{ tags.gladeName }}</div>
          <div class="fb_address">FB搜尋：{{ tags.fbUrl }}</div>
          <div class="ig_address">IG搜尋：{{ tags.igUrl }}</div>
        </div>
      </div>
    </div>
    <div class="cell">
      <div class="cell_item">
        <div class="cellflex">
          <div class="cell_top">
            <div class="top_thumb">
              {{ Number(tags.tagsCount) > 10000 ? (Number(tags.tagsCount) / 10000).toFixed(2) + "萬" :
                  tags.tagsCount
              }}<span>讚</span>
            </div>
            <div class="top_vote">
              {{ Number(tags.votesCount) > 10000 ? (Number(tags.votesCount) / 10000).toFixed(2) + "萬" :
                  tags.votesCount
              }}<span>投票</span>
            </div>
          </div>
          <div class="cell_bottom" v-if="!fieldstatus">
            <div class="bottom_thumb" @click="thumb">
              <div class="thumb_img" v-if="votes.length == 0">
                <img src="~@/assets/img/person10.png" />
              </div>
              <div class="thumb_img" v-else>
                <img src="~@/assets/img/beauty04.png" />
              </div>
              <div class="thumb">按讚</div>
            </div>
            <div class="bottom_vote" @click="vote">
              <div class="vote_img" v-if="thumbs.length == 0">
                <img src="~@/assets/img/person11.png" />
              </div>
              <div class="vote_img" v-else>
                <img src="~@/assets/img/shoucang.png" />
              </div>
              <div class="vote">投票</div>
            </div>
          </div>
          <div style="height: 25px"></div>
        </div>
        <div class="cell_btn">
          <van-button type="primary" :loading="chatloading" @click="sendchat" v-if="is_chat">聊聊</van-button>
        </div>
      </div>
    </div>

    <div style="padding: 0px 20px">
      <div class="introduce">
        <p class="title">
          <img class="img" src="../../../assets/img/introduce.png" alt="" />
          <span class="text">個人介紹</span>
        </p>
        <div class="count">
          <span class="text three" v-html="tags.individualResume" @click="$event.target.classList.toggle('three')"></span>
        </div>
      </div>

      <div class="new_film" v-if="newVideo">
        <p class="title">
          <span class="text">最新影片</span>
          <img class="img1" src="../../../assets/img/new_film01.png" alt="" />
          <img class="img2" src="../../../assets/img/new_film02.png" alt="" />
        </p>
        <div class="film" @click="gofilm">
          <video class="video" :src="getImage(newVideo)"></video>
<!--          <video class="video" :src="getImage(tags.vidio)"></video>-->

          <img class="open" src="../../../assets/img/play.png" alt="" />
        </div>
      </div>
    </div>

    <div class="new_img">
      <div class="contitle">
        <p class="title">
          <span class="text">最新照片</span>
          <img class="img1" src="../../../assets/img/new_img.png" alt="" />
        </p>
        <div class="title_right" @click="$router.push({ path: '/personaldynamic', query: { id: tags.id } })">
          <p class="right_text">查看全部動態</p>
          <p class="right_img"><img src="~@/assets/img/person02.png" /></p>
        </div>
      </div>
      <div class="img_box">
        <img class="img" v-for="(item, index) in imgList" :key="index" :src="getImage(item)" alt=""
          @click="goimg(index)" />
      </div>
      <div class="contime">
        <div class="time_img"><img src="~@/assets/img/person03.png" /></div>
        <div class="newtime">發佈時間：{{ tags.createDate }}</div>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="沒有更多了" @load="onload">
      <div class="list">
        <div class="message">
          <img class="msg_img" src="~@/assets/img/person04.png" />
          <div class="msgtext">留言 ({{ leavewordscount }})</div>
          <img class="msg_imgs" src="~@/assets/img/person05.png" />
        </div>
        <div class="list_item" v-for="(item, index) in comments_list" :key="index">
          <div class="itemflex">
            <div class="flexleft">
              <img class="left_head" src="~@/assets/img/msg.png" />
              <div class="left_text">
                <div class="left_name">{{ item.vipName }}</div>
                <div class="left_time">{{ item.createDate }}</div>
              </div>
            </div>
            <div class="flexright" @click.stop="comment(item)">
              <img class="right_img" src="~@/assets/img/person08.png" />
              <div class="right_text">回覆</div>
            </div>
          </div>
          <div class="answer">{{ item.content }}</div>
          <div v-for="(val, vals) in item.reLeaveWordList" :key="vals">
            <div class="news" v-if="item.subs_loading ? vals < 2 : true">
              <img class="news_img" src="~@/assets/img/msg.png" />
              <div class="news_text">
                <div class="text">{{ val.vipName }}</div>
                <div class="text_detail">{{ val.content }}</div>
              </div>
            </div>
          </div>
          <div class="checknews" v-if="item.reLeaveWordList.length > 2">
            <div class="news_bg" @click.stop="commit(item)" v-if="item.reLeaveWordList.length > 2 && item.subs_loading">
              <div class="bg_num">
                查看其他{{ item.reLeaveWordList.length - 2 }}則回覆
              </div>
              <img class="bg_img" src="~@/assets/img/person06.png" />
            </div>
            <div class="up" @click.stop="commit(item)" v-if="item.reLeaveWordList.length > 2 && !item.subs_loading">
              <div class="up_num">收起</div>
              <img class="up_img" src="~@/assets/img/person07.png" />
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div style="height: 80px"></div>
    <div class="confield" v-show="isShowBottom" @click.stop="field" v-if="!fieldstatus">
      <div class="field">
        <van-field v-model="value" clearable :autofocus="true" :placeholder="placeholder">
          <template #left-icon>
            <div class="icon">
              <img class="icon_img" src="~@/assets/img/person09.png" />
            </div>
          </template>
        </van-field>
      </div>
      <div v-if="value" @click="sendcomments">
        <img class="right_img" src="~@/assets/img/person12.png" />
      </div>
    </div>
    <van-image-preview v-model="show" :images="imgList" @change="onChange" :startPosition="index">
      <!--      <template v-slot:index>{{ `${index + 1}/${images.length}` }}</template>-->
    </van-image-preview>
  </div>
</template>

<script>

import {getImage} from "@/utils/upload";

export default {
  data() {
    return {
      show: false,
      images: [],
      imgList: [], //图片
      value: "", //输入框
      placeholder: "寫留言...",
      autofocus: false,
      comments_list: [], //评论
      loading: false,
      finished: false,
      page: 1,
      subs_page: 1, //子评论页数
      subs_loading: false, //子评论loading
      index: 0, //图片序号
      actid: this.$route.query.actid,
      vipid: this.$route.query.vipid,
      isShowBottom: false, //显示或者隐藏footer
      documentHeight: document.documentElement.clientHeight, //默认屏幕高度
      tags: {}, //投票、点赞
      thumbs: [], //点赞icon
      votes: [], //投票icon
      select: 1, //1 评论 2回复
      targ: {}, //回复对象
      leavewordscount: "", //留言数量
      fieldstatus: this.$route.query.fieldstatus,
      id: "", //会员id
      gladeid: this.$route.query.gladeid, //佳丽id
      chatloading: false,
      newVideo: ''
    };
  },
  computed: {
    //计算评论列表
    is_chat() {
      if (this.id == this.tags.vipId) {
        return false;
      } else {
        return true;
      }
      return false
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.gettags();
    this.get_new_dynamic()
    let pick = JSON.parse(localStorage.getItem("taigu_useres"));
    if (pick) {
      this.id = pick.idDecrypt;
    }
  },
  methods: {
    getImage,
    get_new_dynamic() {
      this.$post({
        url: '/api/Act_state/GetAllAuditStatesByGladeid',
        data: {
          Gladeid: this.gladeid,
          pageIndex: 1,
          pagesize: 100
        },
        success: (res) => {
          let imgList = []
          let videoList = []
          // let list2 = []
          res.data.rows.map((item) => {
            if (item.imgUrl) {
              imgList.push(item)
            } else if (item.videoUrl) {
              videoList.push(item)
            }
          })
          // 对通过的 照片动态 排序
          imgList.sort(function (a, b) {
            return (new Date(a.modifyDate).valueOf()) < (new Date(b.modifyDate).valueOf()) ? 1 : -1
          })
          let dynamic = imgList[0]
          let imgUrl = dynamic.imgUrl.split(',')
          // 判断照片是否通过审核
          if (dynamic.imgAuditStatus1 == 1 && imgUrl[0]) {
            this.imgList.push(imgUrl[0])
          }
          if (dynamic.imgAuditStatus2 == 1 && imgUrl[1]) {
            this.imgList.push(imgUrl[1])
          }
          if (dynamic.imgAuditStatus3 == 1 && imgUrl[2]) {
            this.imgList.push(imgUrl[2])
          }
          // 对通过的 影片动态 排序
          videoList.sort(function (a, b) {
            return (new Date(a.modifyDate).valueOf()) < (new Date(b.modifyDate).valueOf()) ? 1 : -1
          })
          let video = videoList[0]
          this.newVideo = video.videoUrl
        },
        tip: () => { }
      })
    },
    share() {
      var theResult = {
        // title: `${this.tags.gladeName}`,
        url: `${this.httpPath}/#/personalfile?actid=${this.actid}&gladeid=${this.gladeid}`,
        // "og-image": `${this.httpPath}${this.tags.photo1}`,
        // "meta-description": `${this.tags.tags.individualResume}`,
      };
      var str = JSON.stringify(theResult);
      if (navigator.userAgent.match(/(Android)/i)) {
        window.android.Androidrichshare(str);
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        window.webkit.messageHandlers.IOSrichshare.postMessage(str);
      }
    },
    //获取回复评论目标(一级 二级)
    comment(data) {
      this.isShowBottom = true;
      this.targ = data;
      if (data) {
        // 二级
        this.placeholder = `回覆 ${data.vipName}`;
      }
      this.select = 2;
      this.autofocus = true;
    },
    sendcomments() {
      let url = "";
      let data = {};
      if (this.select == 1) {
        url = "/api/Act_leaveword/SaveLeaveWord";
        data = {
          gladeid: this.tags.id,
          vipid: this.id,
          leaveWord: this.value,
        };
      } else {
        url = "/api/Act_leaveword/SaveLeaveWord";
        data = {
          gladeid: this.tags.id,
          vipid: this.id,
          leaveWordid: this.targ.id,
          leaveWord: this.value,
        };
      }
      this.$post({
        url,
        data,
        success: (res) => {
          this.$toast('留言發送成功')
          this.value = "";
          this.targ = {};
          this.placeholder = "寫留言...";
          this.select = 1;
          setTimeout(() => {
            this.page = 1;
            this.onload();
          }, 1500);
        },
        tip: () => {
          if (this.select == 1) {
            this.value = "";
            this.placeholder = "寫留言...";
            this.select = 1;
          } else if (this.select == 2) {
            this.value = "";
            this.placeholder = `${this.placeholder}`;
            this.select = 2;
            this.autofocus = true;
          }
        },
      });
    },
    commit(item) {
      item.subs_loading = !item.subs_loading;
    },
    field() {
      // this.autofocus = true
      this.placeholder = `${this.placeholder}`;
      this.isShowBottom = true;
    },
    getthumb() {
      this.$post({
        url: "/api/Act_votes/GetVotesByVipid",
        data: {
          activeid: this.actid,
          gladeid: this.gladeid,
          vipid: this.id,
        },
        success: (res) => {
          this.thumbs = res.data;
        },
        tip: () => { },
      });
    },
    getvote() {
      this.$post({
        url: "/api/Act_tags/GetTagsByVipid",
        data: {
          activeid: this.actid,
          gladeid: this.gladeid,
          vipid: this.id,
        },
        success: (res) => {
          this.votes = res.data;
        },
        tip: () => { },
      });
    },
    //点赞
    thumb() {
      this.$post({
        url: "/api/Act_tags/TagsByVip",
        data: {
          activeid: this.actid,
          gladeid: this.tags.id,
          vipid: this.id,
        },
        success: (res) => {
          this.$toast(`${res.message}`);
          this.gettags()
        },
        tip: () => { },
      });
    },
    //投票
    vote() {
      this.$post({
        url: "/api/Act_votes/VotesByVip",
        data: {
          activeid: this.actid,
          gladeid: this.tags.id,
          vipid: this.id,
        },
        success: (res) => {
          this.$toast(`${res.message}`);
          this.gettags()
        },
        tip: () => { },
      });
    },
    box() {
      this.isShowBottom = false;
    },
    say() {
      this.isShowBottom = true;
    },
    gettags() {
      this.$get({
        url: "/api/Act_glade/GetGladeInfo",
        data: {
          gladeid: this.gladeid,
        },
        success: (res) => {
          this.tags = res.data;
          // this.imgList = res.data.photo2.split(",")
          // this.newVideo = res.data.vidio
          this.getthumb(); //是否投票
          this.getvote(); //是否点赞
          this.onload();
        },
        tip: () => { },
      });
    },
    goimg(index) {
      this.index = index;
      // this.images = []
      // this.images.push(this.getImage(item));
      this.show = true
      // ImagePreview({
      //   startPosition: index,
      //   images: this.imgList,
      // });
    },
    onChange(index) {
      this.index = index;
    },
    gofilm() {
      this.$router.push({
        path: "/watch_film",
        query: {
          item: this.newVideo,
        },
      });
    },
    onload() {
      var page = this.page++;
      this.$post({
        url: "/api/Act_leaveword/GetLeaveWordByGladeid",
        data: {
          gladeid: this.tags.id,
          leaveWordid: 0,
          pageIndex: 1,
          pagesize: 10,
          pagesizeChr: "10",
        },
        success: (res) => {
          this.leavewordscount = res.data.leavewordscount;
          if (res.data.leavewordlist.length == 0) {
            this.finished = true;
          } else {
            res.data.leavewordlist.forEach((item) => {
              if (item.reLeaveWordList.length > 2) {
                item.subs_loading = true;
              } else {
                item.subs_loading = false;
              }
            });
            var list = res.data.leavewordlist;
            if (page == 1) {
              this.comments_list = list;
            } else {
              for (var i in list) {
                this.comments_list.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // var scroll = scrollTop - this.i;
      this.i = scrollTop;
      // if (scroll < 0) {
      //   this.isShowBottom = false;
      // } else {
      //   this.isShowBottom = true;
      // }

      if (scrollTop === 0 || scrollTop < 30) {
        this.isShowBottom = false;
      } else {
        this.isShowBottom = true
      }
    },
    sendchat() {
      this.chatloading = true
      this.$post({
        url: "/api/Act_chatroom/GetChatroomByVipid",
        data: {
          vipid: this.id,
          tovipid: this.vipid,
        },
        success: (res) => {
          this.chatloading = false
          this.$router.push({
            path: "/chat_box",
            query: {
              roomId: res.data.roomId,
              roomName: res.data.roomName,
              vipid: this.id,
              tovipid: this.vipid,
              type: 1,
            },
          });
        },
        tip: () => {
          setTimeout(() => {
            this.chatloading = false
          }, 1500);
        },
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style scoped lang="less">
.box {
  position: relative;
  /deep/ .van-swipe-item {
    z-index: 10000;
  }
  /deep/ .van-nav-bar--fixed {
    z-index: 99;
  }
  .right_img {
    width: 48px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .heard {
    padding: 30px;
    .heardflex {
      display: flex;
      align-items: center;
      .header_border {
        position: relative;
        width: 206px;
        height: 206px;
        border-radius: 50%;
        overflow: hidden;
        background: url("../../../assets/img/head_box.png") no-repeat;
        background-size: 100%;
        margin-right: 20px;
        .header_img {
          position: absolute;
          width: 164px;
          height: 164px !important;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .flex_right {
        .name {
          font-size: 32px;
          font-weight: 550;
          color: #ffffff;
        }
        .fb_address {
          width: 100%;
          font-size: 24px;
          font-weight: 300;
          color: #b8b8d2;
          line-height: 1.5;
          padding-top: 10px;
        }
        .ig_address {
          width: 100%;
          font-size: 24px;
          font-weight: 300;
          color: #b8b8d2;
          line-height: 1.5;
          padding-top: 10px;
        }
      }
    }
  }
  .cell {
    padding: 0 40px 2vw;
    .cell_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #353842;
      .cellflex {
        //width: 100%;
        .cell_top {
          display: flex;
          align-items: center;
          .top_thumb {
            //width: 28%;
            display: flex;
            align-items: center;
            font-size: 30px;
            font-weight: 550;
            color: #ffffff;
            span {
              font-size: 24px;
              color: #b8b8d2;
              padding-left: 10px;
            }
          }
          .top_vote {
            display: flex;
            align-items: center;
            font-size: 30px;
            font-weight: 550;
            color: #ffffff;
            padding-left: 40px;
            span {
              font-size: 24px;
              color: #b8b8d2;
              padding-left: 10px;
            }
          }
        }
        .cell_bottom {
          display: flex;
          align-items: center;
          padding: 30px 0 0px;
          .bottom_thumb {
            display: flex;
            align-items: center;
            .thumb_img {
              width: 32px;
              height: 32px;
              line-height: 1;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .thumb {
              font-size: 24px;
              font-weight: 400;
              color: #f2f2f2;
              padding-left: 10px;
            }
          }
          .bottom_vote {
            display: flex;
            align-items: center;
            padding-left: 98px;
            .vote_img {
              width: 32px;
              height: 32px;
              line-height: 1;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .vote {
              font-size: 24px;
              font-weight: 400;
              color: #f2f2f2;
              padding-left: 10px;
            }
          }
        }
      }
      .cell_btn {
        /deep/ .van-button {
          height: 60px;
          background: linear-gradient(180deg, #4e9cda 0%, #29487b 100%);
          border-radius: 8px 8px 8px 8px;
          border: none;
        }
      }
    }
  }
  .introduce {
    margin-top: 32px;
    .title {
      margin-bottom: 20px;
      .img {
        width: 28px;
        height: 28px;
        margin-right: 20px;
      }
      .text {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
      }
    }
    .count {
      padding: 28px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 24px;
      .text {
        font-size: 28px;
        font-weight: 400;
        color: #d3d3d3;
        line-height: 40px;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 3;
      }
    }
  }
  .new_film {
    margin-top: 50px;
    .title {
      position: relative;
      margin-bottom: 32px;
      .text {
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
        line-height: 35px;
      }
      .img1 {
        position: absolute;
        width: 6.1vw;
        height: 6.1vw;
        top: -2.3vw;
        left: 12.7vw;
      }
      .img2 {
        position: absolute;
        width: 2.5vw;
        height: 2.5vw;
        bottom: -1vw;
        left: 12.5vw;
      }
    }
    .film {
      position: relative;
      width: 100%;
      height: 400px;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 0px 20px 8px rgba(165, 203, 255, 0.32);
      .video {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
      .open {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .new_img {
    margin-top: 50px;
    .contitle {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      .title {
        position: relative;
        margin-bottom: 32px;
        .text {
          font-size: 30px;
          font-weight: 400;
          color: #ffffff;
          line-height: 35px;
        }
        .img1 {
          position: absolute;
          width: 6.1vw;
          height: 6.1vw;
          top: -0.3vw;
          left: 11.7vw;
        }
      }
      .title_right {
        display: flex;
        .right_text {
          font-size: 26px;
          font-weight: 300;
          color: #5a5a75;
        }
        .right_img {
          width: 40px;
          height: 40px;
          line-height: 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .img_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      .img {
        width: 212px;
        height: 212px;
        background: #010228;
        box-shadow: 0 0 20px 8px rgba(255, 94, 244, 0.32);
        border-radius: 28px 28px 28px 28px;
        opacity: 1;
        border: 3px solid #d58fb7;
        margin-bottom: 25px;
        margin-left: 25px;
      }
    }
    .contime {
      display: flex;
      padding: 10px 30px 25px;
      .time_img {
        width: 30px;
        height: 30px;
        line-height: 1.3;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .newtime {
        font-size: 24px;
        font-weight: 300;
        color: #b8b8d2;
        padding-left: 10px;
      }
    }
  }
  .list {
    padding: 0 30px 0px;
    .message {
      position: relative;
      padding: 15px 0 25px;
      .msgtext {
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
      }
      .msg_img {
        width: 22px;
        height: 22px;
        object-fit: contain;
        line-height: 1;
        position: absolute;
        transform: translate(260%, -20%);
      }
      .msg_imgs {
        width: 16px;
        height: 16px;
        object-fit: contain;
        line-height: 1;
        position: absolute;
        transform: translate(240%, -30%);
      }
    }
    .list_item {
      padding: 10px 0 20px;
      .itemflex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .flexleft {
          display: flex;
          align-items: center;
          .left_head {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: contain;
          }
          .left_text {
            padding-left: 20px;
            .left_name {
              font-size: 26px;
              font-weight: 400;
              color: #d3d3d3;
            }
            .left_time {
              font-size: 20px;
              font-weight: 400;
              color: #5a5a75;
            }
          }
        }
        .flexright {
          display: flex;
          align-items: center;
          .right_img {
            width: 32px;
            height: 32px;
            object-fit: contain;
          }
          .right_text {
            font-size: 24px;
            font-weight: 400;
            color: #ff51c1;
            padding-left: 5px;
          }
        }
      }
      .answer {
        font-size: 26px;
        font-weight: 400;
        color: #d3d3d3;
        padding: 0 20px 30px 80px;
      }
      .news {
        width: 100%;
        padding: 0 20px 30px 80px;
        display: flex;
        .news_img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: contain;
        }
        .news_text {
          padding-left: 20px;
          .news_text {
            font-size: 26px;
            font-weight: 400;
            color: #d3d3d3;
          }
          .text_detail {
            font-size: 26px;
            font-weight: 400;
            color: #d3d3d3;
            padding-top: 5px;
          }
        }
      }
      .checknews {
        display: flex;
        padding: 0 20px 30px 150px;
        .news_bg {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.15);
          border-radius: 30px 30px 30px 30px;
          padding: 10px 20px;
          .bg_num {
            font-size: 24px;
            font-weight: 400;
            color: #d3d3d3;
          }
          .bg_img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
        .up {
          display: flex;
          align-items: center;
          .up_num {
            font-size: 24px;
            font-weight: 400;
            color: #5a5a75;
          }
          .up_img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }
    }
  }
  /deep/ input::-webkit-input-placeholder {
    font-size: 26px;
    font-weight: 400;
    color: #5a5a75;
  }
  .confield {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #393a42;
    position: fixed;
    bottom: 0;
    padding: 0 30px 0;
    background: #191a23;
    .field {
      width: 100%;
      padding: 20px 0px 30px;
      .icon {
        line-height: 1.5;
        .icon_img {
          width: 36px;
          height: 36px;
          object-fit: contain;
        }
      }
      /deep/ .van-field {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 24px 24px 24px 24px;
        opacity: 1;
        border: 2px solid rgba(251, 249, 255, 0.15);
        .van-field__control {
          color: #ffffff;
        }
      }
    }
    .right_img {
      width: 88px;
      height: 88px;
      object-fit: contain;
      margin-left: 20px;
    }
  }
}
</style>
