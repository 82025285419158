<template>
  <div class="boxs">
    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onload" :immediate-check="false">
        <div>
          <div class="list_item">
            <div class="item_left" v-if="list.length > 2" @click.stop="
                $router.push({
                  path: '/personalfile',
                  query: {
                    actid: list[2].actID,
                    vipid: list[2].vipId,
                    gladeid: list[2].id,
                  }})">
              <div class="left_head">
                <img class="head_img" v-lazy="getImage(list[2].photo1)" />
              </div>
              <div class="left_name">
                <div class="name two">{{ list[2].gladeName }}</div>
                <div class="name_logo" v-if="list[2].isNewState == 1">NEW</div>
              </div>
              <div class="left_num">
                <div class="num_img">
                  <img src="~@/assets/img/beauty04.png" />
                </div>
                <div class="num">{{ list[2].tagsCount }}</div>
              </div>
              <div class="left_img" @click.stop="thumb(list[2])">
                <img v-lazy="isvote(list[2].id) ? thumbimg : no_thumb" alt="" />
              </div>
            </div>
            <div class="item_top" v-if="list.length > 0" @click.stop="
                $router.push({
                  path: '/personalfile',
                  query: {
                    actid: list[0].actID,
                    vipid: list[0].vipId,
                    gladeid: list[0].id,
                  }})" >
              <div class="top_head">
                <img class="head_img" v-lazy="getImage(list[0].photo1)" />
              </div>
              <div class="top_name">
                <div class="name two">{{ list[0].gladeName }}</div>
                <div class="name_logo" v-if="list[0].isNewState == 1">NEW</div>
              </div>
              <div class="top_num">
                <div class="num_img">
                  <img src="~@/assets/img/beauty04.png" />
                </div>
                <div class="num">{{ list[0].tagsCount }}</div>
              </div>
              <div class="top_img" @click.stop="thumb(list[0])">
                <img v-lazy="isvote(list[0].id) ? thumbimg : no_thumb" alt="" />
              </div>
            </div>
            <div class="item_right" v-if="list.length > 1" @click.stop="
                $router.push({
                  path: '/personalfile',
                  query: {
                    actid: list[1].actID,
                    vipid: list[1].vipId,
                    gladeid: list[1].id,
                  }})">
              <div class="right_head">
                <img class="head_img" v-lazy="getImage(list[1].photo1)" />
              </div>
              <div class="right_name">
                <div class="name two">{{ list[1].gladeName }}</div>
                <div class="name_logo" v-if="list[1].isNewState == 1">NEW</div>
              </div>
              <div class="right_num">
                <div class="num_img">
                  <img src="~@/assets/img/beauty04.png" />
                </div>
                <div class="num">{{ list[1].tagsCount }}</div>
              </div>
              <div class="right_img" @click.stop="thumb(list[1])">
                <img v-lazy="isvote(list[1].id) ? thumbimg : no_thumb" alt="" />
              </div>
            </div>
          </div>
	        <div class="check">
<!--		        <div class="check_text">看更多排行</div>-->
	        </div>
          <div class="cell">
            <div class="cells" v-for="(item, index) in list" :key="index" @click.stop="
                $router.push({
                  path: 'personalfile',
                  query: {
                    actid: item.actID,
                    vipid: item.vipId,
                    gladeid: item.id,
                  }})">
              <div class="cell_item" v-if="index > 2">
                <div class="cell_flex">
                  <div class="cell_num">{{ index + 1 }}.</div>
                  <div class="cell_head">
                    <img v-lazy="getImage(item.photo1)" />
                  </div>
                  <div class="cell_name">
                    <div class="item_name">
                      <div class="name">{{ item.gladeName }}</div>
                      <div class="name_logo" v-if="item.isNewState == 1">
                        NEW
                      </div>
                    </div>
                    <div class="item_num">
                      <div class="num_img">
                        <img src="~@/assets/img/beauty04.png" />
                      </div>
                      <div class="num">{{ item.tagsCount }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="index > 2" class="cell_zan" @click.stop="thumb(item)">
                  <img v-lazy="isvote(item.id) ? thumbimg : no_thumb" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getImage } from "@/utils/upload";

export default {
  name: "Thumb",
  props: ["info", "id"],
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      actid: "", //活动id
      decid: "", //会员id
      thumbs: [], //已投票
      thumbimg: require("../assets/img/beauty04.png"),
      no_thumb: require("../assets/img/person10.png"),
    };
  },
  created() {
    let pick = JSON.parse(localStorage.getItem("taigu_useres"));
    if (pick) {
      this.decid = pick.idDecrypt;
      this.actid = pick.actid;
    }
    this.getinfo();
  },
  methods: {
    getImage,
    getinfo() {
      this.$post({
        url: "/api/Act_tags/GetTagsByVipid",
        data: {
          activeid: this.actid,
          vipid: this.decid,
        },
        success: (res) => {
          res.data.forEach((item) => {
            this.thumbs.push(item.gladeid);
          });
	        this.finished = false
	        this.page = 1
          this.onload();
        },
        tip: () => {},
      });
    },
    thumb(id) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.$post({
        url: "/api/Act_tags/TagsByVip",
        data: {
          activeid: id.actID,
          gladeid: id.id,
          vipid: this.decid,
        },
        success: (res) => {
          this.$toast.clear();
          this.$toast(`${res.message}`);
          this.getinfo();
        },
        tip: () => {},
      });
    },
    onload() {
      var page = this.page++;
      this.$post({
        url: "/api/Act_glade/GetGladeRankByActid",
        data: {
          pageIndex: page,
          pagesize: 10,
          actid: this.info.id,
          sort: "TagsCount",
        },
        success: (res) => {
          this.$toast.clear();
          if (!res || res.data.length == 0) {
            this.finished = true;
          } else {
            var list = res.data;
            if (page == 1) {
              this.list = list;
            } else {
              for (var i in list) {
                this.list.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
    isvote(id) {
      let thumbs = false;
      this.thumbs.find((item) => {
        if (item == id) {
          thumbs = true;
        }
      });
      return thumbs;
    },
  },
};
</script>

<style scoped lang="less">
.boxs {
  padding: 20px 30px 30px;

  .list {
    padding: 0px 20px 30px;
    border-radius: 32px 32px 32px 32px;
    border: 4px solid #F6838A;

    .list_item {
      display: flex;
      align-items: center;

      .item_top {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top_head {
          width: 172px;
          height: 232px;
          background: url("~@/assets/img/no1.png") no-repeat;
          background-size: 100% 100%;

          .head_img {
            width: 163px;
            height: 154px;
            border-radius: 50%;
            margin: 72px 0 0 5px;
            object-fit: cover;
          }
        }

        .top_name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 24px;

          .name {
            width: 57%;
            font-size: 30px;
            font-weight: 550;
            color: #ffffff;
            padding-right: 5px;
            text-align: center;
          }

          .name_logo {
            background: #f98186;
            //font-size: 15px;
	          transform: scale(0.6);
            border-radius: 30px;
            padding: 4px 12px;
          }
        }

        .top_num {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0 30px;

          .num_img {
            width: 26px;
            height: 26px;
            padding-right: 5px;
            line-height: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .num {
            font-size: 20px;
            font-weight: 400;
            color: #fedd66;
          }
        }

        .top_img {
          width: 48px;
          height: 43px;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .item_left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 120px 0px 0 0;

        .left_head {
          width: 142px;
          height: 202px;
          background: url("~@/assets/img/no3.png") no-repeat;
          background-size: 100% 100%;

          .head_img {
            width: 133px;
            height: 135px;
            border-radius: 50%;
            margin: 62px 0 0 5px;
            object-fit: cover;
          }
        }

        .left_name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 24px;

          .name {
            width: 55%;
            font-size: 30px;
            font-weight: 550;
            color: #ffffff;
            padding-right: 5px;
            text-align: center;
          }

          .name_logo {
            background: #f98186;
            //font-size: 15px;
	          transform: scale(0.6);
            border-radius: 30px;
            padding: 4px 12px;
          }
        }

        .left_num {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0 30px;

          .num_img {
            width: 26px;
            height: 26px;
            padding-right: 5px;
            line-height: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .num {
            font-size: 20px;
            font-weight: 400;
            color: #fedd66;
          }
        }

        .left_img {
          width: 48px;
          height: 43px;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .item_right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 120px 0px 0 0px;

        .right_head {
          width: 142px;
          height: 202px;
          background: url("~@/assets/img/no2.png") no-repeat;
          background-size: 100% 100%;

          .head_img {
            width: 133px;
            height: 135px;
            border-radius: 50%;
            margin: 62px 0 0 5px;
            object-fit: cover;
          }
        }

        .right_name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 24px;

          .name {
            width: 56%;
            font-size: 30px;
            font-weight: 550;
            color: #ffffff;
            padding-right: 5px;
            text-align: center;
          }

          .name_logo {
            background: #f98186;
            //font-size: 15px;
	          transform: scale(0.6);
            border-radius: 30px;
            padding: 4px 12px;
          }
        }

        .right_num {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0 30px;

          .num_img {
            width: 26px;
            height: 26px;
            padding-right: 5px;
            line-height: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .num {
            font-size: 20px;
            font-weight: 400;
            color: #fedd66;
          }
        }

        .right_img {
          width: 48px;
          height: 43px;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
	.check{
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0 60px;
		.check_text{
			width: 182px;
			height: 60px;
			border: 2px solid #5C68DE;
			border-radius: 30px;
			font-size: 24px;
			font-weight: 550;
			color: rgba(255,255,255,0.9);
			display: flex;
			justify-content: center;
			align-items:  center;
		}
	}
  .cell {
    width: 100%;
    padding: 0 40px 0 0;

    .cell_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid #373669;

      .cell_flex {
        width: 100%;
        display: flex;
        align-items: center;

        .cell_num {
          width: 16%;
          text-align: center;
          font-size: 30px;
          font-weight: 550;
          color: #fd828f;
        }

        .cell_head {
          width: 100px;
          height: 100px;
	        flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .cell_name {
	        width: 360px;
          padding-left: 20px;

          .item_name {
            display: flex;
            align-items: center;

            .name {
              font-size: 30px;
              font-weight: 550;
              color: #ffffff;
              padding-right: 15px;
            }

            .name_logo {
              background: #f98186;
              //font-size: 15px;
	            transform: scale(0.6);
              border-radius: 30px;
              padding: 4px 12px;
              flex: none;
            }
          }

          .item_num {
            display: flex;
            align-items: center;

            .num_img {
              width: 26px;
              height: 26px;
              padding-right: 5px;
              line-height: 1;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .num {
              font-size: 24px;
              font-weight: 400;
              color: #fedd66;
            }
          }
        }
      }

      // .cell_item:last-child {
      //   border-bottom: none;
      // }
      .cell_zan {
        width: 72px;
        height: 72px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .cells:last-child {
    .cell_item {
      border-bottom: none;
    }
  }
}
</style>
