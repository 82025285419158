<template>
  <div>
    <van-nav-bar
      title="個人動態"
      left-arrow
      @click-left="$router.go(-1)"
      fixed
      :border="false"
    />
    <div class="type">
      <div>發佈類型</div>
      <div class="change">
        <span class="film" :class="num == 2 ? 'film1' : ''" @click="change(2)"
          >照片</span
        >
        <span class="photo" :class="num == 1 ? 'photo1' : ''" @click="change(1)"
          >影片</span
        >
      </div>
    </div>
    <div class="list" v-show="2 == num">
      <van-uploader
        v-model="fileList"
        :max-count="3"
        :after-read="upload1"
        :before-delete="deleteImg1"
      >
        <img class="card" src="../../../assets/img/upload_img.png" alt="" />
      </van-uploader>
    </div>
    <div class="list" v-show="1 == num">
      <van-uploader
        v-model="photoList"
        :max-count="1"
        :after-read="upload2"
        accept="video/*"
        :before-delete="deleteImg2"
      >
        <img class="card" src="../../../assets/img/upload_film.png" alt="" />
      </van-uploader>
    </div>
    <div class="tips">今日可發佈1條影片，3張照片</div>
    <div class="button">
      <van-button
        round
        color="#3c73ac"
        class="buttons"
        @click="SaveStates"
        loading-text="發佈中..."
        :loading="loading"
        >確定發佈</van-button
      >
    </div>
    <div class="real-time">
      <img src="../../../assets/img/information.png" alt="" />
    </div>
  </div>
</template>

<script>
import ImageCompressor from "js-image-compressor/src";
import {AwsUpdate} from "@/utils/upload";

export default {
  data() {
    return {
      userList: [],
      loading: false, //是否显示加载状态
      fileList: [],
      fileLists: "",
      photo1: [],
      film1: [],
      photoList: [],
      num: "2",
      list: {},
      loca: {},
      fileImg: [],
      photo3: [],
    };
  },
  // 获取本地数据
  created() {
    this.loca = JSON.parse(localStorage.getItem("taigu_useres"));
    this.get_glade();
  },
  methods: {
    // 删除上传的图片
    deleteImg1(file, detail) {
      this.film1.splice(detail.index, 1);
      this.fileList.splice(detail.index, 1);
    },
    deleteImg2(file, detail) {
      this.photo1.splice(detail.index, 1);
      this.photoList.splice(detail.index, 1);
    },
    get_glade() {
      this.$get({
        url: "/api/Act_glade/GetGladeInfo",
        data: {
          gladeid: this.loca.gladeid,
        },
        success: (res) => {
          this.userList = res.data;
        },
        tip: () => {},
      });
    },
    // 判断照片视频
    change(index) {
      this.num = index;
    },
    // 上传图片
    upload1(file) {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "上傳中……",
      });
      if (file.file.size / 1024 < 5210) {
        /*let formData = new FormData();
        formData.append("fileInput", file.file);
        this.$post({
          url: "/api/Act_state/Upload",
          data: formData,
          upload: true,
          success: (res) => {
            this.$toast.clear();
            this.$toast(res.message);
            this.film1.push(res.data + file.file.name);
          },
          tip: () => {},
        });*/

        AwsUpdate(file.file)
          .then(res => {
            this.$toast.clear()
            this.$toast('文件上傳成功')
            this.film1.push(res.Location)
          })
          .catch(rej => {
            this.$toast.clear()
            this.$toast('文件上傳失敗')
          })
      } else {
        this.compress(file);
      }
    },
    // 压缩图片
    compress(file) {
      let options = {
        file: file.file,
        quality: 0.4,
        mimeType: "image/jpeg",
        maxWidth: 5000,
        maxHeight: 5000,
        minWidth: 50,
        minHeight: 50,
        convertSize: Infinity,
        loose: true,
        redressOrientation: true,
        beforeCompress: (res) => {
          console.log(res);
        },
        success: (res) => {
          let file = res;
          this.upload1({
            file,
          });
        },
        error: (err) => {
          this.$toast.clear();
          console.log(err);
        },
      };
      new ImageCompressor(options);
    },
    // 上传影视
    upload2(file) {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "上傳中……",
      });
      let formData = new FormData();
      formData.append("fileInput", file.file);
      this.$post({
        url: "/api/Act_state/Upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.$toast.clear();
          this.$toast(res.message);
          this.photo1.push(res.data + file.file.name);
        },
        tip: () => {},
      });
    },
    // 数据
    SaveStates() {
      //判断是否上传了相关内容
      if (this.film1.length <= 0 && this.num == 2)
        return this.$toast("請上傳照片");
      if (this.photo1.length <= 0 && this.num == 1)
        return this.$toast("請上傳影片");
      if (this.num == 1) {
        this.film1 = [];
        this.fileList = [];
      } else {
        this.photo1 = [];
        this.photoList = [];
      }
      // 上传内容
      let img = this.film1 + "";
      let video = this.photo1 + "";
      // 显示加载状态
      this.loading = true;
      let pud = this.film1.length;
      let data = {
        id: 0,
        gladeName: this.userList.gladeName,
        actStateType: this.num,
        publicCount: pud,
        passCount: 0,
        createDate: "",
        auditStatus: 0,
        activityId: this.loca.actid,
        createID: 0,
        creator: "",
        gladeId: this.loca.gladeid,
        imgUrl: img,
        modifier: "",
        modifyDate: "",
        modifyID: 0,
        remark: "",
        imgAuditStatus1: 0,
        imgAuditStatus2: 0,
        imgAuditStatus3: 0,
        videoUrl: video,
        videoAuditStaus: "",
        groupId: "",
        auditResion: "",
      };
      this.$post({
        url: "/api/Act_state/SaveStates",
        raw: true,
        data,
        success: (res) => {
          this.$toast(res.message);
          // 上传成功关闭加载状态
          this.loading = false;
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        },
        tip: () => {
          // 上传失败关闭加载状态
          this.loading = false;
        },
      });
    },

    // 上传影片封面图
    // upload4(file) {
    //   this.$toast.loading({
    // 	  forbidClick: true,
    // 	  duration: 3000,
    //   });
    //   let formData = new FormData();
    //   formData.append("fileInput", file.file);
    //   this.$post({
    // 	  url: "/api/Act_state/Upload",
    // 	  data: formData,
    // 	  upload: true,
    // 	  success: (res) => {
    // 		  this.$toast.clear();
    // 		  this.photo3.push(res.data + file.file.name);
    // 	  },
    // 	  tip: () => { },
    //   });
    // },
  },
};
</script>
<style scoped lang="less">
.card {
  width: 220px;
  height: 220px;
}
.top {
  font-size: 24px;
  font-weight: 400;
  color: #b8b8d2;
  line-height: 33px;
  margin-bottom: 20px;
}

.type {
  padding: 32px 32px;
  color: #b8b8d2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 30px;

  .change {
    width: 30.5vw;
    height: 60px;
    position: relative;
    font-size: 26px;
    border-radius: 10px;
    overflow: hidden;

    .film1 {
      background: linear-gradient(180deg, #4e9cda 0%, #29487b 100%);
    }

    .film {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      line-height: 60px;
      width: 122px;
      height: 60px;
      display: inline-block;
      -webkit-clip-path: polygon(0 0, 100% 0%, 94% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0%, 94% 100%, 0% 100%);
    }

    .photo1 {
      background: linear-gradient(180deg, #4e9cda 0%, #29487b 100%);
    }

    .photo {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 60px;
      width: 122px;
      height: 60px;
      display: inline-block;
      -webkit-clip-path: polygon(7% 0, 100% 0%, 100% 100%, 0 100%);
      clip-path: polygon(7% 0, 100% 0%, 100% 100%, 0 100%);
    }
  }
}

/deep/.list {
  padding: 0 32px;
  padding-bottom: 24px;

  .van-uploader__preview-image {
    width: 220px;
    height: 220px;
    border-radius: 16px 16px 16px 16px;
  }

  .van-uploader__upload {
    width: 220px;
    height: 220px;
    border-radius: 16px 16px 16px 16px;
  }

  .van-uploader__wrapper {
    flex-wrap: nowrap;
  }

  .van-uploader__preview-delete {
    border-radius: 50%;
    top: 8px;
    right: 8px;
    width: 36px;
    height: 36px;
  }

  .van-uploader__preview-delete-icon:before {
    font-size: 6vw;
  }

  .van-icon-photograph:before {
    background-image: url("../../../assets/img/upload_film.png");
  }
}

.tips {
  padding: 0 32px;
  font-size: 24px;
  color: #b8b8d2;
}

.button {
  margin-top: 550px;
  padding-left: 13vw;

  .buttons {
    width: 550px;
    height: 92px;
    font-size: 32px;
  }
}

.real-time {
  width: 100%;
  text-align: center;
  margin: 80px 0;

  img {
    height: 30px;
  }
}

.count_box {
  padding: 0 32px;
  padding-bottom: 24px;

  .top {
    font-size: 28px;
    font-weight: 400;
    color: #b8b8d2;
    line-height: 33px;
    margin-bottom: 20px;
  }
}
</style>
