<template>
    <div>
        <van-nav-bar title="完善資料" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
        <div class="count">
            <div class="interval"></div>
            <div class="title">設置會員暱稱</div>
            <van-field v-model="value"  placeholder="請輸入會員暱稱" maxlength="15" clearable/>
            <div class="tips">中文、數字、字母組合最多15個字符</div>
            <button @click="getInto">進入</button>
        </div>
	    <div class="real-time">
		    <img src="../../../assets/img/information.png" alt="" />
	    </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: "",
            loca:{}
        };
    },
    created() {
        this.loca = JSON.parse(localStorage.getItem("taigu_user"))
    },


    methods:{
        // 获取数据
        getInto(){
            this.$get({
                url:"/api/Act_vip/UpdateVipName",
                data:{
                    vipId: this.loca.idDecrypt,
                    vipName:this.value
                },
                success: (res) => {
                    this.$toast("修改成功")
                    setTimeout(()=>{
                        this.$router.go(-1)
                    },1500)
                },
                tip: () => {}
            })
        },
    }
};
</script>
<style scoped lang="less">
.count {
    padding: 0 32px;

    .interval {
        height: 42px;
    }

    .title {
        color: #b8b8d2;
        font-size: 32px;
        padding: 18px;
    }

    /deep/.van-cell{
        background-color: #2b2c35;
    }
    /deep/.van-cell::after{
        border-bottom: none;
    }

    /deep/.van-field__control{
        color: #fff;
    }

    .tips {
        font-size: 22px;
        padding: 24px 0;
        text-align: center;
        color: #5a5a75;
    }

    button {
        width: 550px;
        height: 92px;
        background: linear-gradient(180deg, #4e9cda 0%, #29487b 100%);
        border-radius: 46px 46px 46px 46px;
        margin: 82px 0 68px 68px;
        font-size: 32px;
        color: #ffffff;

        button {
            width: 550px;
            height: 92px;
            background: linear-gradient(180deg, #4e9cda 0%, #29487b 100%);
            border-radius: 46px 46px 46px 46px;
            margin: 82px 0 68px 68px;
            font-size: 32px;
            color: #ffffff;
        }
    }
}
.real-time {
	width: 100%;
	text-align: center;
	//margin: 80px 0;
	position: absolute;
	bottom: 54px;

	img {
		height: 30px;
	}
}
</style>
