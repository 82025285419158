<template>
  <div class="dynamic_box">
    <van-nav-bar title="個人動態" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
    <van-list v-model="loading" :finished="finished" finished-text="沒有更多了" @load="onload" offset="300" :error.sync="error" error-text="請求失敗，點擊重新加載">
      <van-cell v-for="(item, index) in imgList" :key="index">
        <div class="modular">
          <div class="left">
            <div class="spot"></div>
            <div class="time">發佈時間：{{ item.time }}</div>
          </div>
          <div class="right">
            <div class="imgs">
              <img src="../../../assets/img/dynamic.png" alt="" v-if="item.type == 0" />
              <img src="../../../assets/img/dynamic3.png" alt="" v-if="item.type == 1" />
              <img src="../../../assets/img/dynamic2.png" alt="" v-if="item.type == 2" />
            </div>
            <div class="word_one" v-if="item.type == 0">
              {{ examine[item.type] }}
            </div>
            <div class="word_two" v-if="item.type == 1">
              {{ examine[item.type] }}
            </div>
            <div class="word_three" v-if="item.type == 2">
              {{ examine[item.type] }}
            </div>
          </div>
        </div>
        <div class="video" @click="go_film(item.img)" v-if="item.mold == 'video'" :style="color[item.type]">
          <div class="player-container">
            <video :src="getImage(item.img)"></video>
	          <img class="open" src="~@/assets/img/play.png" alt="" />

          </div>
        </div>

        <div v-if="item.mold == 'img'" class="picture">
          <div class="pictures" v-for="(item1, index1) in item.img" :key="index1" @click="goimg(item1.url, index1)">
            <img class="img" v-lazy="getImage(item1.url)" v-if="item1.type == 0" :style="color[item1.type]" />
            <img class="img" v-lazy="getImage(item1.url)" v-if="item1.type == 1" :style="color[item1.type]" />
            <img class="img" v-lazy="getImage(item1.url)" v-if="item1.type == 2" :style="color[item1.type]" />
          </div>
        </div>
      </van-cell>
    </van-list>
    <div class="btn_box">
      <van-button round color="#3c73ac" class="button" @click="$router.push('/information')">發佈動態</van-button>

    </div>
	  <div class="real-time">
		  <img src="../../../assets/img/information.png" alt="" />
	  </div>

    <van-image-preview v-model="show" :images="images" @change="onChange">
    </van-image-preview>
  </div>
</template>
<script>
import { getImage } from "@/utils/upload";

export default {
  data() {
    return {
      images: [],
      show: false,
      index: "",
      imgLists: [],
      list: {},
      time: "",
      lists: [],
      loading: false, //是否处于加载状态
      finished: false, //加载完成后的文提案
      imgList: [], //基础数据
      page: 1, //分页
      page_size: 10, //每页条数
      total: 0, //数据总条数
      error: false,
      examine: ["審核中", "審核通過", "審核拒絕"],
      color: [
        " box-shadow: 0px 0px 10px 8px rgba(255, 94, 244, 0.32);", // 绿
        " box-shadow: 0px 0px 10px 8px rgba(94, 255, 121, 0.32);", // 红
      ],
    };
  },
  created() {
    this.list = JSON.parse(localStorage.getItem("taigu_useres"));
    this.onload();
  },
  methods: {
    getImage,
    // 图片预览
    goimg(url, index) {
      this.index = index;
	    this.images = []
	    this.images.push(this.getImage(url));
      this.show = true;
    },
    onChange(index) {
      this.index = index;
    },

    // 视频跳转
    go_film(img) {
      this.$router.push({
        name: "watch_film",
        query: {
          item: img,
        }
      });
    },

    onload() {
      this.loading = true;
      this.$post({
        url: "/api/Act_state/GetAllStatesByGladeid",
        data: {
          Gladeid: this.list.gladeid,
          pageIndex: this.page,
          pagesize: this.page_size,
        },
        success: (res) => {
	        let list = res.data.rows
	        list.sort(function(a,b){
		        return (new Date(a.createDate).valueOf()) < (new Date(b.createDate).valueOf()) ? 1 : -1
	        });
          list.map((item) => {
            if (item.imgUrl) {
              let num = [];
              let imgs = item.imgUrl.split(",");

              if (item.imgAuditStatus1 == 0 && imgs[0]) {
                num.push({
                  url: imgs[0],
                  type: item.imgAuditStatus1,
                });
              } else if (item.imgAuditStatus1 == 1 && imgs[0]) {
                num.push({
                  url: imgs[0],
                  type: item.imgAuditStatus1,
                });
              }

              if (item.imgAuditStatus2 == 0 && imgs[1]) {
                num.push({
                  url: imgs[1],
                  type: item.imgAuditStatus2,
                });
              } else if (item.imgAuditStatus2 == 1 && imgs[1]) {
                num.push({
                  url: imgs[1],
                  type: item.imgAuditStatus2,
                });
              }

              if (item.imgAuditStatus3 == 0 && imgs[2]) {
                num.push({
                  url: imgs[2],
                  type: item.imgAuditStatus3,
                });
              } else if (item.imgAuditStatus3 == 1 && imgs[2]) {
                num.push({
                  url: imgs[2],
                  type: item.imgAuditStatus3,
                });
              }
              this.imgList.push({
                type: item.auditStatus,
                time: item.createDate,
                img: num,
                mold: "img",
              });
            } else if (item.videoUrl) {
              this.imgList.push({
                type: item.videoAuditStaus,
                time: item.createDate,
                img: item.videoUrl,
                mold: "video",
              });
            }
          });
          this.loading = false;
          this.page++;
          if (res.data.rows.length == 0) {
            this.finished = true; //结束加载状态
          }
        },
        tip: () => {},
      });
    },
  },
};
</script>

<style scoped lang="less">
.van-list {
  min-height: calc(100vh - 53vw);
}

.dynamic_box {
  position: relative;
}

/deep/.van-cell {
  background-color: #191a23;
  padding-top: 0;
  padding-bottom: 0;

  .van-cell__value {
    overflow: visible;
  }
}

/deep/.van-cell::after {
  border-bottom: none;
}

.modular {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 18px 0;

  .left {
    display: flex;
    align-items: center;

    .spot {
      width: 12px;
      height: 12px;
      background: linear-gradient(117deg, #9c12b9 0%, #f20395 100%);
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  .right {
    font-size: 22px;
    display: flex;

    .imgs {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .word_one {
      padding-left: 4px;
      color: #3db9ff;
    }

    .word_two {
      padding-left: 4px;
      color: #46ff85;
    }

    .word_three {
      padding-left: 4px;
      color: #ff4085;
    }
  }
}

.video {
  margin-top: 18px;
  margin-bottom: 18px;
  height: 400px;
  width: 686px;
  border-radius: 28px 28px 28px 28px;
  opacity: 1;
  overflow: hidden;

	.player-container {

		.open {
			position: absolute;
			width: 80px;
			height: 80px;
			top: 60%;
			left: 50%;
			transform: translate(-50%, -60%);
		}
	}

  img {
    width: 100%;
    height: 100%;
  }
}

.picture {
  display: flex;

  .pictures {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 18px;
    padding-bottom: 18px;

    .img {
      box-sizing: border-box;
      width: 212px;
      height: 212px;
      background: #010228;
      border-radius: 28px 28px 28px 28px;
      opacity: 1;
      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 28px 28px 28px 28px;
      }
    }

    .color_one {
      box-shadow: 0px 0px 20px 8px rgba(64, 155, 216, 0.767);
      border: 1px solid #61abf7;
    }

    .color_two {
      box-shadow: 0px 0px 20px 8px rgba(94, 255, 121, 0.32);
      border: 3px solid #32663b;
    }

    .color_three {
      box-shadow: 0px 0px 20px 8px rgba(255, 94, 244, 0.32);
      border: 1px solid #d58fb7;
    }

    .img_one:nth-child(3n + 3) {
      margin-right: 0;
    }

    .img_two:nth-child(3n + 3) {
      margin-right: 0;
    }

    .img_three:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
}

/deep/.van-sticky {
  text-align: center;
}

.btn_box {
  z-index: 999;
  width: 100%;
  position: sticky;
  bottom: 0;
}

.button {
  width: 550px;
  height: 92px;
  font-size: 32px;
  left: 50%;
  transform: translate(-50%, 0);
}

.tips {
  width: 300px;
  height: 30px;
  transform: translate(76%, 200%);

  img {
    width: 100%;
    height: 100%;
  }
}

.real-time {
	width: 100%;
	text-align: center;
	margin: 80px 0;

	img {
		height: 30px;
	}
}
</style>
