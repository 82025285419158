<template>
  <div class="box">
    <van-nav-bar title="個人動態" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
    <div style="height: 20px"></div>
    <van-list v-model="loading" :finished="finished" finished-text="沒有更多了" @load="onload" :immediate-check="false">
      <div v-for="(item, index) in imgList" :key="index">
        <div style="padding: 0 20px" v-if="item.type == 'video'">
          <div class="new_film">
            <div class="confilm">
              <div class="title">
                <div class="title_cycle"></div>
                <div class="title_time">發佈時間：{{ item.time }}</div>
              </div>
              <div class="film_right" @click="share">
                <img class="right_img" src="~@/assets/img/person01.png" />
                <div class="share">分享</div>
              </div>
            </div>
            <div class="film" @click="gofilm(item.url)">
              <video class="video" :src="getImage(item.url)"></video>
              <img class="open" src="../../../assets/img/play.png" alt="" />
            </div>
          </div>
        </div>

        <div class="new_img" v-if="item.type == 'img'">
          <div class="contitle">
            <div class="title">
              <div class="title_cycle"></div>
              <div class="title_time">發佈時間：{{ item.time }}</div>
            </div>
            <div class="film_right" @click="share">
              <img class="right_img" src="~@/assets/img/person01.png" />
              <div class="share">分享</div>
            </div>
          </div>
          <div class="img_box">
            <img class="img" v-for="(val, vals) in item.url" :key="vals" v-lazy="getImage(val.url)"
              @click="goimg(val.url, vals)" />
          </div>
        </div>
      </div>
    </van-list>
    <van-image-preview v-model="show" :images="images" @change="onChange">
      <!--      <template v-slot:index>{{ `${index + 1}/${images.length}` }}</template>-->
    </van-image-preview>
    <div class="real-time">
      <img src="../../../assets/img/information.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getImage } from "@/utils/upload";

export default {
  data() {
    return {
      imgList: [],
      index: "",
      id: this.$route.query.id,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      images: [],
      show: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.onload();
  },
  methods: {
    getImage,
    share() {
      var theResult = {
        url: `${this.httpPath}#/personaldynamic?id=${this.id}`,
      };
      var str = JSON.stringify(theResult);
      if (navigator.userAgent.match(/(Android)/i)) {
        window.android.Androidrichshare(str);
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        window.webkit.messageHandlers.IOSrichshare.postMessage(str);
      }
    },
    onload() {
      var page = this.page++;
      this.$post({
        url: "/api/Act_state/GetAllAuditStatesByGladeid",
        data: {
          Gladeid: this.id,
          pageIndex: page,
          pagesize: 10,
        },
        success: (res) => {
          if (!res.data || res.data.rows.length == 0) {
            this.finished = true;
          } else {
            let list = res.data.rows.sort(function (a, b) {
              return (new Date(a.modifyDate).valueOf()) < (new Date(b.modifyDate).valueOf()) ? 1 : -1
            });
            if (page == 1) {
              this.list = list
              this.list.map((item) => {
                if (item.imgUrl) {
                  let num = [];
                  let imgs = item.imgUrl.split(",");

                  if (item.imgAuditStatus1 == 1 && imgs[0]) {
                    num.push({
                      url: imgs[0],
                    });
                  }

                  if (item.imgAuditStatus2 == 1 && imgs[1]) {
                    num.push({
                      url: imgs[1],
                    });
                  }

                  if (item.imgAuditStatus3 == 1 && imgs[2]) {
                    num.push({
                      url: imgs[2]
                    });
                  }
                  this.imgList.push({
                    type: "img",
                    time: item.modifyDate,
                    url: num
                  });
                } else if (item.videoUrl && item.videoAuditStaus == 1) {
                  this.imgList.push({
                    type: "video",
                    time: item.modifyDate,
                    url: item.videoUrl,
                  });
                }
              })
            } else {
              for (let i in list) {
                this.list.push(list[i])
              }
              this.list.map((item) => {
                if (item.imgUrl) {
                  let num = [];
                  let imgs = item.imgUrl.split(",");

                  if (item.imgAuditStatus1 == 1 && imgs[0]) {
                    num.push({
                      url: imgs[0],
                    });
                  }

                  if (item.imgAuditStatus2 == 1 && imgs[1]) {
                    num.push({
                      url: imgs[1],
                    });
                  }

                  if (item.imgAuditStatus3 == 1 && imgs[2]) {
                    num.push({
                      url: imgs[2],
                    });
                  }
                  this.imgList.push({
                    type: "img",
                    time: item.modifyDate,
                    url: num,
                  });
                } else if (item.videoUrl && item.videoAuditStaus == 1) {
                  this.imgList.push({
                    type: "video",
                    time: item.modifyDate,
                    url: item.videoUrl,
                  });
                }
              })
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
    getinfo() { },
    goimg(url, index) {
      this.index = index;
      this.images = []
      this.images.push(this.getImage(url));
      this.show = true;
    },
    onChange(index) {
      this.index = index;
    },
    gofilm(video) {
      this.$router.push({
        path: "/watch_film",
        query: {
          item: video,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  /deep/ .van-swipe-item {
    z-index: 10000;
  }
  /deep/ .van-nav-bar--fixed {
    z-index: 99;
  }
  .new_film {
    padding-top: 1.1vw;
    .confilm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      .title {
        display: flex;
        align-items: center;
        .title_cycle {
          width: 12px;
          height: 12px;
          background: linear-gradient(117deg, #9c12b9 0%, #f20395 100%);
          border-radius: 50%;
          margin-right: 10px;
        }
        .title_time {
          font-size: 24px;
          font-weight: 400;
          color: #b8b8d2;
        }
      }
      .film_right {
        display: flex;
        align-items: center;
        .right_img {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
        .share {
          font-size: 24px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
    .film {
      position: relative;
      width: 100%;
      height: 400px;
      border-radius: 15px;
      overflow: hidden;
      margin-bottom: 20px;
      box-shadow: 0px 0px 20px 8px rgba(165, 203, 255, 0.32);
      .video {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
      .open {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .new_img {
    .contitle {
      display: flex;
      justify-content: space-between;
      padding: 10px 30px 30px;
      .title {
        display: flex;
        align-items: center;
        .title_cycle {
          width: 12px;
          height: 12px;
          background: linear-gradient(117deg, #9c12b9 0%, #f20395 100%);
          border-radius: 50%;
          margin-right: 10px;
        }
        .title_time {
          font-size: 24px;
          font-weight: 400;
          color: #b8b8d2;
        }
      }
      .film_right {
        display: flex;
        align-items: center;
        .right_img {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
        .share {
          font-size: 24px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
    .img_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      .img {
        width: 212px;
        height: 212px;
        background: #010228;
        box-shadow: 0 0 20px 8px rgba(70, 255, 133, 0.5);
        border-radius: 28px 28px 28px 28px;
        opacity: 1;
        border: 3px solid #d58fb7;
        margin-bottom: 25px;
        margin-left: 25px;
      }
    }
    .contime {
      display: flex;
      padding: 10px 30px 40px;
      .time_img {
        width: 30px;
        height: 30px;
        line-height: 1.3;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .newtime {
        font-size: 24px;
        font-weight: 300;
        color: #b8b8d2;
        padding-left: 10px;
      }
    }
  }
}
.real-time {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 54px;
  //padding: 57px 0 40px 0;
  img {
    height: 30px;
  }
}
</style>
