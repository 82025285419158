<template>
  <div class="box">
    <van-nav-bar title="留言板" left-arrow @click-left="$router.go(-1)" fixed :border='false' />
    <van-list v-model="loading" :finished="finished" finished-text="沒有更多了" @load="onload">
      <div class="list">
        <div class="message">
          <img class="msg_img" src="~@/assets/img/person04.png">
          <div class="msgtext">留言 ({{leavewordscount}})</div>
          <img class="msg_imgs" src="~@/assets/img/person05.png">
        </div>
        <div class="list_item" v-for="(item,index) in comments_list" :key="index">
          <div class="itemflex">
            <div class="flexleft">
              <img class="left_head" src="~@/assets/img/msg.png">
              <div class="left_text">
                <div class="left_name">{{item.vipName}}</div>
                <div class="left_time">{{item.createDate}}</div>
              </div>
            </div>
            <div class="flexright" @click.stop="comment(item)">
              <img class="right_img" src="~@/assets/img/person08.png">
              <div class="right_text">回覆</div>
            </div>
          </div>
          <div class="answer">{{item.content}}</div>
          <div v-for="(val,vals) in item.reLeaveWordList" :key="vals">
            <div class="news" v-if="item.subs_loading?vals<2:true">
              <img class="news_img" src="~@/assets/img/msg.png">
              <div class="news_text">
                <div class="text">{{val.vipName}}</div>
                <div class="text_detail">{{val.content}}</div>
              </div>
            </div>
          </div>
          <div class="checknews" v-if="item.reLeaveWordList.length>2">
            <div class="news_bg" @click.stop="commit(item)" v-if="item.reLeaveWordList.length>2&&item.subs_loading">
              <div class="bg_num">查看其他{{item.reLeaveWordList.length-2}}則回覆</div>
              <img class="bg_img" src="~@/assets/img/person06.png">
            </div>
            <div class="up" @click.stop="commit(item)" v-if="item.reLeaveWordList.length>2&&!item.subs_loading">
              <div class="up_num">收起</div>
              <img class="up_img" src="~@/assets/img/person07.png">
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div style="height:80px"></div>
    <div class="confield" @click.stop="field">
      <div class="field">
        <van-field v-model="value" clearable :autofocus='true' :placeholder="placeholder">
          <template #left-icon>
            <div class="icon">
              <img class="icon_img" src="~@/assets/img/person09.png">
            </div>
          </template>
        </van-field>
      </div>
      <div v-if="value" @click="sendcomments"><img class="right_img" src="~@/assets/img/person12.png"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      placeholder: "寫留言...",
      autofocus: false,
      comments_list: [],//评论
      loading: false,
      finished: false,
      page: 1,
      subs_page: 1,//子评论页数
      subs_loading: false,//子评论loading
      select: 1,//1 评论 2回复
      targ: {}, //回复对象
      vipid: "",
      actid: "",
      info: {},
      leavewordscount: "", //留言数量

    }
  },
  created() {
    window.scrollTo(0, 0)
    let pick = JSON.parse(localStorage.getItem("taigu_useres"))
    if (pick) {
      this.vipid = pick.idDecrypt
      this.actid = pick.actid
      this.getinfo()

    }
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/api/Act_glade/GetGladeByvipid",
        data: {
          actid: this.actid,
          vipid: this.vipid
        },
        success: (res) => {
          this.info = res.data
          this.onload()
        },
        tip: () => { },
      })
    },
    //获取回复评论目标(一级 二级)
    comment(data) {
      this.targ = data
      if (data) {
        // 二级
        this.placeholder = `回復 ${data.vipName}`
      }
      this.select = 2
      this.autofocus = true
    },
    sendcomments() {
      let url = ''
      let data = {}
      if (this.select == 1) {
        url = '/api/Act_leaveword/SaveLeaveWord'
        data = {
          gladeid: this.info.id,
          vipid: this.vipid,
          leaveWord: this.value
        }
      } else {
        url = '/api/Act_leaveword/SaveLeaveWord'
        data = {
          gladeid: this.info.id,
          vipid: this.vipid,
          leaveWordid: this.targ.id,
          leaveWord: this.value
        }
      }
      this.$post({
        url,
        data,
        success: (res) => {
          this.value = ''
          this.targ = {}
          this.placeholder = '寫留言...'
          this.select = 1
          setTimeout(() => {
            this.page = 1
	          this.$toast('留言發送成功')
            this.onload()
          }, 1500);
        },
        tip: () => {
          if (this.select == 1) {
            this.value = "";
            this.placeholder = "寫留言...";
            this.select = 1;
          } else if (this.select == 2) {
            this.value = "";
            this.placeholder = `${this.placeholder}`;
            this.select = 2;
            this.autofocus = true;
          }
        }
      })
    },
    commit(item) {
      item.subs_loading = !item.subs_loading;
    },
    field() {
      this.autofocus = true
      this.placeholder = `${this.placeholder}`
    },
    onload() {
      var page = this.page++
      this.$post({
        url: "/api/Act_leaveword/GetLeaveWordByGladeid",
        data: {
          gladeid: this.info.id,
          leaveWordid: 0,
          pageIndex: 1,
          pagesize: 10,
          pagesizeChr: "10",
        },
        success: (res) => {
          this.leavewordscount = res.data.leavewordscount
          if (res.data.leavewordlist.length == 0) {
            this.finished = true
          } else {
            res.data.leavewordlist.forEach(item => {
              if (item.reLeaveWordList.length > 2) {
                item.subs_loading = true
              } else {
                item.subs_loading = false
              }
            })
            var list = res.data.leavewordlist
            if (page == 1) {
              this.comments_list = list
            } else {
              for (var i in list) {
                this.comments_list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  }

}
</script>

<style scoped lang='less'>
/deep/ .van-toast {
  background-color: transparent !important;
}
.box {
  width: 100%;
  min-height: 100vh;
  position: relative;
  .list {
    padding: 60px 30px 0;
    overflow-y: auto;
    .message {
      position: relative;
      padding-bottom: 25px;
      .msgtext {
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
      }
      .msg_img {
        width: 22px;
        height: 22px;
        object-fit: contain;
        line-height: 1;
        position: absolute;
        transform: translate(260%, -20%);
      }
      .msg_imgs {
        width: 16px;
        height: 16px;
        object-fit: contain;
        line-height: 1;
        position: absolute;
        transform: translate(240%, -30%);
      }
    }
    .list_item {
      padding: 10px 0 20px;
      .itemflex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .flexleft {
          display: flex;
          align-items: center;
          .left_head {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: contain;
          }
          .left_text {
            padding-left: 20px;
            .left_name {
              font-size: 26px;
              font-weight: 400;
              color: #d3d3d3;
            }
            .left_time {
              font-size: 20px;
              font-weight: 400;
              color: #5a5a75;
            }
          }
        }
        .flexright {
          display: flex;
          align-items: center;
          .right_img {
            width: 32px;
            height: 32px;
            object-fit: contain;
          }
          .right_text {
            font-size: 24px;
            font-weight: 400;
            color: #ff51c1;
            padding-left: 5px;
          }
        }
      }
      .answer {
        font-size: 26px;
        font-weight: 400;
        color: #d3d3d3;
        padding: 0 20px 30px 80px;
      }
      .news {
        width: 100%;
        padding: 0 20px 30px 80px;
        display: flex;
        .news_img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: contain;
        }
        .news_text {
          padding-left: 20px;
          .news_text {
            font-size: 26px;
            font-weight: 400;
            color: #d3d3d3;
          }
          .text_detail {
            font-size: 26px;
            font-weight: 400;
            color: #d3d3d3;
            padding-top: 5px;
          }
        }
      }
      .checknews {
        display: flex;
        padding: 0 20px 30px 150px;
        .news_bg {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.15);
          border-radius: 30px 30px 30px 30px;
          padding: 10px 20px;
          .bg_num {
            font-size: 24px;
            font-weight: 400;
            color: #d3d3d3;
          }
          .bg_img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
        .up {
          display: flex;
          align-items: center;
          .up_num {
            font-size: 24px;
            font-weight: 400;
            color: #5a5a75;
          }
          .up_img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }
    }
  }
  /deep/ input::-webkit-input-placeholder {
    font-size: 26px;
    font-weight: 400;
    color: #5a5a75;
  }
  .confield {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #393a42;
    background: #191a23;
    position: fixed;
    z-index: 10;
    padding: 0 30px 0;
    bottom: 0;
    .field {
      width: 100%;
      padding: 20px 0 30px;
      .icon {
        .icon_img {
          width: 36px;
          height: 36px;
          object-fit: contain;
          line-height: 1;
        }
      }
      /deep/ .van-field {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 24px 24px 24px 24px;
        opacity: 1;
        border: 2px solid rgba(251, 249, 255, 0.15);
        .van-field__control {
          color: #ffffff;
        }
      }
    }
    .right_img {
      width: 88px;
      height: 88px;
      object-fit: contain;
      margin-left: 20px;
    }
  }
}
</style>