<template>
  <div class="activity_box">
    <van-nav-bar title="活動" fixed :border="false" />
	  <div v-html="arr.actContent"></div>
	  <div class="btn_box">
		  <button v-if="show" class="btn" @click="$router.push('/enroll')">活動報名</button>
		  <div class="nav_a" @click="$router.push('/privacy')">
			  隱私權版權宣告
		  </div>
	  </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      arr: {},
      num: {},
      show: false,
	    value: ''
    }
  },
  created() {
    this.get_activity()
    let url = window.location.href
    this.value = url.split('?value=')[1]
  },
  methods: {
    getUserCheck(value) {
      this.$post({
        url: "/api/Act_vip/UserCheck",
        data: { value },
        success: (res) => {
          this.$toast.clear();
          this.show = true
          this.LoginImSys(res.data.idDecrypt)
          let obj = {
            idDecrypt: res.data.idDecrypt, //会员id
            timeDecrypt: res.data.timeDecrypt, //时间戳
            tokenDecrypt: res.data.tokenDecrypt, //token
            usertypeDecrypt: res.data.usertypeDecrypt, //会员类型
          };
          localStorage.setItem("taigu_user", JSON.stringify(obj));
          const nowTime = new Date().valueOf()
          const finalStopTime = new Date(this.arr.finalStopTime.replace(/-/g, "/")).getTime() // 决赛阶段结束时间
          if (res.message.indexOf("新會員") != -1 && nowTime < finalStopTime) {
            this.$toast({
              message: res.message,
              onClose: ()=>{
                if (this.$route.name !== "user") {
                  return this.$router.push("user");
                }
              }
            });
          }
          // this.num = JSON.parse(localStorage.getItem("taigu_user"));
          this.num = obj
          this.get_gladeId()
        },
        tip: () => { },
      });
    },
    LoginImSys(vipid) {
      localStorage.removeItem("jwt_im_userId");
      this.$post({
        url: "/api/Act_chatroom/LoginImSys",
        data: { vipid },
        success: (res) => {
          if (res.data.userId) {
            localStorage.setItem("jwt_im_userId", res.data.userId);
          }
        },
        tip: () => { },
      });
    },
    // 获取佳丽信息
    get_gladeId() {
      this.$get({
        url: '/api/Act_glade/GetGladeByvipid',
        data: {
          actid: this.arr.id,
          vipid: this.num.idDecrypt
        },
        success: (res) => {
          if (!res.status) return this.show = true
          this.show = false
          if (res.data.auditStatus == 1) {
            this.set_local(res.data.id)
          } else {
            this.set_local('')
          }
        },
        tips: () => {
          this.get_vipId()
        }
      })
    },
    // 获取会员信息
    get_vipId() {
      this.$get({
        url: '/api/Act_vip/GetVipInfoById',
        data: {
          vipid: this.num.idDecrypt
        },
        success: (res) => {
          this.show = true
          this.set_local('')
        },
        tip: () => { }
      })
    },
    // 添加本地存储
    set_local(gladeid) {
      // 用户是会员也是佳丽的情况
      let obj = {
        idDecrypt: this.num.idDecrypt,  //会员id
        timeDecrypt: this.num.timeDecrypt,  //时间戳
        tokenDecrypt: this.num.tokenDecrypt,  //token
        usertypeDecrypt: this.num.usertypeDecrypt, //会员类型
        gladeid: gladeid, // 佳丽id
        actid: this.arr.id // 活动id
      }
      // 用户是会员但不是佳丽的情况
      let obj2 = {
        idDecrypt: this.num.idDecrypt,  //会员id
        timeDecrypt: this.num.timeDecrypt,  //时间戳
        tokenDecrypt: this.num.tokenDecrypt,  //token
        usertypeDecrypt: this.num.usertypeDecrypt, //会员类型
        actid: this.arr.id // 活动id
      }
      if (gladeid) {
        localStorage.setItem("taigu_useres", JSON.stringify(obj))
      } else {
        localStorage.setItem("taigu_useres", JSON.stringify(obj2))
      }
      this.go_next()
    },
    // 获取当前活动
    get_activity() {
      this.$get({
        url: "/api/Act_activity/GetActivity",
        success: (res) => {
          this.arr = res.data
	        if (this.value) {
		        this.getUserCheck(this.value);
	        }
        },
        tip: () => {
          this.$router.push({
            name: 'un_activity'
          })
        },
      })
      // 获取系统配置
      this.$get({
        url: "/api/Act_options/GetOptions",
        success: (res) => {
          let obj = {
            vipid_header: res.data.text1,
            tovipid_header: res.data.text2,
          }
          localStorage.setItem("options", JSON.stringify(obj))
        },
        tip: () => { },
      })
    },
    // 选择跳转的页面
	  //
    go_next() {
      let applyStartTime = new Date(this.arr.applyStartTime.replace(/-/g, "/")).getTime() // 报名开始时间
      let applyStopTime = new Date(this.arr.applyStopTime.replace(/-/g, "/")).getTime() // 报名结束时间
      let votingStartTime = new Date(this.arr.votingStartTime.replace(/-/g, "/")).getTime() // 海选阶段开始时间
      let votingStopTime = new Date(this.arr.votingStopTime.replace(/-/g, "/")).getTime() // 海选阶段结束时间
      let finalStartTime = new Date(this.arr.finalStartTime.replace(/-/g, "/")).getTime() // 决赛阶段开始时间
      let finalStopTime = new Date(this.arr.finalStopTime.replace(/-/g, "/")).getTime() // 决赛阶段结束时间
      let nowTime = new Date().valueOf()

      if (applyStartTime > nowTime) {
        this.$router.replace("un_activity?msg=活動未開始")
      } else if (applyStopTime > nowTime && applyStartTime < nowTime) {

      } else if (votingStartTime > nowTime && applyStopTime < nowTime) {
        this.$router.replace("un_activity?msg=海選未開始")
      } else if (votingStopTime > nowTime && votingStartTime < nowTime) {
        this.$router.replace('beauty')
      } else if (finalStartTime > nowTime && votingStopTime < nowTime) {
        this.$router.replace("un_activity?msg=決賽未開始")
      } else if (finalStopTime > nowTime && finalStartTime < nowTime) {
        this.$router.replace('beauty')
      } else {
        this.$router.replace('beauty')
      }
    }
  },
}
</script>

<style scoped lang="less">
.activity_box {
	position: relative;
	.btn_box {
		position: sticky;
		bottom: 48px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.btn {
			width: 550px;
			height: 92px;
			border-radius: 46px 46px 46px 46px;
			opacity: 1;
			border: 5px solid #d6b5c3;
			font-size: 32px;
			font-weight: bold;
			//color: #ffffff;
			margin-bottom: 25px;
			//background-color: #191A23;
      background-color: #d8b7bf;
      color: #191a23;
		}
		.nav_a {
			width: 100%;
			height: 32px;
			font-size: 24px;
			font-weight: 400;
			color: #f88084;
			line-height: 28px;
			text-decoration: underline;
			text-align: center;
		}
	}
  //.count {
  //  position: relative;
  //  width: 100%;
  //  background: no-repeat 100%;
  //  background-size: contain;
  //  height: calc(100vh - 12.3vw);
  //  .van-swipe {
  //    width: 100%;
  //    height: 100%;
  //    .van-swipe__track {
  //      .van-swipe-item {
  //        img {
  //          width: 100%;
  //          height: 100%;
  //          object-fit: contain;
  //        }
  //      }
  //    }
  //  }
  //
  //}
}
</style>
