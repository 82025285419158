import AWS from 'aws-sdk'

const accessKeyId = 'AKIA5VIXBEL65IJGSB6B'
const secretAccessKey = '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'

AWS.config.update({
    region: 'ap-northeast-1', // aws服务器所在地区编号
    apiVersion: '2006-03-01', // 版本
    accessKeyId,
    secretAccessKey
})

const s3 = new AWS.S3()

export function AwsUpdate(file) {
    const fileName = file.name.split('.')
    const type = fileName.pop()
    const name = fileName.join('.')
    const uploadParams = {
        Bucket: 'queedom', // 桶名称
        Key: `queendom/${name}${new Date().getTime()}.${type}`, // 图片名称
        Body: file, // file
        "ACL": "public-read", // 类型
        // 'Access-Control-Allow-Origin': '*' // 策应请求头
    }
    const imgFile = new Promise((resolve, reject) => {
        s3.upload(uploadParams, (err, data) => {
            if (err) {
                console.log('err', err)
                reject(err)
            }
            if (data) {
                console.log('data', data)
                resolve(data)
            }
        })
    })
    return imgFile
}

export function getImage(url) {
    if (url) {
        if (url.startsWith('http') || url.startsWith('https')) {
            return url
        }
        return this.httpPath + url
    }
    return url
}
