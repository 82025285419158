<template>
  <div class="personal_box">
    <van-nav-bar title="個人中心" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
    <div class="count">
      <div class="header">
        <div class="header_border" @click="to_go()">
          <img class="header_img" :src="getImage(glader.photo1)" alt="" />
        </div>
        <div class="right">
          <p class="name">{{ glader.gladeName }}</p>
          <p class="activity">
            <span class="activity_count">{{ activity.actName }}</span>
          </p>
          <div class="btn_box">
            <button class="btn btn1" @click="$router.push('/msgboard')">
              <span class="text1">留言板</span>
            </button>
            <button class="btn btn2" @click="$router.push('/chat')">
              <span class="text2">聊聊列表</span>
            </button>
            <button class="btn btn3" @click="$router.push('/dynamic')">
              <span class="text3">我的動態</span>
            </button>
          </div>
        </div>
      </div>
      <div class="rank">
        <p class="rank_box">
          <span class="top">NO.{{ rankIng.sortNO || 0 }}</span>
          <span class="bottom">當前名次</span>
        </p>
        <p class="rank_box">
          <span class="top">{{ Number(rankIng.votesCount) > 10000 ? (Number(rankIng.votesCount) / 10000).toFixed(1) + "萬"
              : rankIng.votesCount || 0
          }}</span>
          <span class="bottom">選票數</span>
        </p>
        <p class="rank_box">
          <span class="top">{{ Number(rankIng.tagsCount) > 10000 ? (Number(rankIng.tagsCount) / 10000).toFixed(1) + "萬" :
              rankIng.tagsCount || 0
          }}</span>
          <span class="bottom">個讚數</span>
        </p>
      </div>
      <div class="address">
        <p class="addressList">
          <span class="address_box">
            <span class="left">FB搜尋：</span>
            <span class="right">{{ glader.fbUrl }}</span>
          </span>
          <span class="btn" @click="alert_address('FB')">
            <img class="img" src="../../../assets/img/shu.png" alt="" />
            編輯
          </span>
        </p>
        <p style="height: 14px"></p>
        <p class="addressList">
          <span class="address_box">
            <span class="left">IG搜尋：</span>
            <span class="right">{{ glader.igUrl }}</span>
          </span>
          <span class="btn" @click="alert_address('IG')">
            <img class="img" src="../../../assets/img/shu.png" alt="" />
            編輯
          </span>
        </p>
        <van-popup v-model="show" class="alert_address" :close-on-click-overlay="false">
          <p class="title">編輯地址</p>
          <van-cell-group>
            <van-field v-model="address" clearable placeholder="請輸入地址" />
          </van-cell-group>
          <div class="btn_box">
            <button class="btn btn1" @click="define()">確定</button>
            <button class="btn btn2" @click="cancel()">取消</button>
          </div>
        </van-popup>
      </div>

      <div class="introduce">
        <p class="title">
          <img class="img" src="~@/assets/img/introduce.png" alt="" />
          <span class="text">個人介紹</span>
        </p>
        <div class="count">
          <span class="text three" v-html="glader.individualResume" @click="$event.target.classList.toggle('three')"></span>
        </div>
      </div>
      <div class="new_film">
        <p class="title">
          <span class="text">最新影片</span>
          <img class="img1" src="~@/assets/img/new_film01.png" alt="" />
          <img class="img2" src="~@/assets/img/new_film02.png" alt="" />
        </p>
        <div class="film" @click="go_film()">
          <video class="video" :src="getImage(newVideo)"></video>
          <!--	        <img class="video" :src="httpPath + glader.vidioImage" alt="">-->
          <img class="open" src="~@/assets/img/play.png" alt="" />
        </div>
      </div>
      <div class="new_img">
        <p class="title">
          <span class="text">最新照片</span>
          <img class="img1" src="~@/assets/img/new_img.png" alt="" />
        </p>
        <van-list v-model="loading" :finished="finished" finished-text="沒有更多了" @load="onLoad">
          <van-row gutter="20">
            <van-col span="8" v-for="(item, index) in imgList" :key="index" class="img_box">
              <img class="img" alt="" v-lazy="getImage(item)" @click="go_head_img(item)" />
            </van-col>
          </van-row>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { getImage } from '@/utils/upload'

export default {
  created() {
    window.scrollTo(0, 0);
    this.num = JSON.parse(localStorage.getItem("taigu_useres"));
    this.get_activity();
    this.get_allImg()
  },
  data() {
    return {
      loading: false,
      finished: false,
      pageIndex: 1,
      glader: {}, // 个人信息
      activity: {}, // 当前活动信息
      num: {}, // localstorage信息
      rankIng: {}, // 排名信息
      imgList: [], // 最新照片列表
      newVideo: '', // 最新影片
      address: "", // 点击修改按钮 修改输入框内容
      alert: "", // 用户点击编辑的是哪个地址
      show: false, // 控制修改地址弹窗的显示隐藏
    };
  },
  methods: {
    getImage,
    // 获取佳丽所有动态
    get_allImg() {
      this.$post({
        url: "/api/Act_state/GetAllAuditStatesByGladeid",
        data: {
          Gladeid: this.num.gladeid,
          pageIndex: this.pageIndex,
          pagesize: 10,
        },
        success: (res) => {
          this.loading = false
          if (res.data.rows.length == 0) {
            this.finished = true
            this.loading = false
          }
          let list = res.data.rows
          let img = []
          let video = []
          list.map((item) => {
            if (item.imgUrl) {
              img.push(item)
            } else if (item.videoUrl) {
              video.push(item)
            }
          })
          video.sort(function (a, b) {
            return new Date(a.modifyDate).valueOf() < new Date(b.modifyDate).valueOf() ? 1 : -1
          })
          this.newVideo = video[0].videoUrl
          img.sort(function (a, b) {
            return (new Date(a.modifyDate).valueOf()) < (new Date(b.modifyDate).valueOf()) ? 1 : -1
          }).map((item) => {
            let imgList = item.imgUrl.split(',')
            // console.log(imgList);
            if (item.imgAuditStatus1 == 1 && imgList[0]) {
              this.imgList.push(imgList[0])
            }
            if (item.imgAuditStatus2 == 1 && imgList[1]) {
              this.imgList.push(imgList[1])
            }
            if (item.imgAuditStatus3 == 1 && imgList[2]) {
              this.imgList.push(imgList[2])
            }
          })
        },
        tip: () => { },
      });
    },
    onLoad() {
      this.pageIndex++
      this.loading = true
      this.get_allImg()
    },
    // 获取当前可用活动
    get_activity() {
      this.$get({
        url: "/api/Act_activity/GetActivity",
        success: (res) => {
          this.activity = res.data;
          this.get_glade();
        },
      });
    },
    // 获取佳丽个人信息
    get_glade() {
      this.$get({
        url: "/api/Act_glade/GetGladeByvipid",
        data: {
          actid: this.activity.id,
          vipid: this.num.idDecrypt,
        },
        success: (res) => {
          this.glader = res.data;
          this.get_rank();
        },
        tip: () => { },
      });
    },
    // 获取排名，投票，点赞数
    get_rank() {
      this.$post({
        url: "/api/Act_glade/GetGladeRankByGladeid",
        data: {
          actid: this.activity.id,
          gladeid: this.glader.id,
          // sort: "Sort",
          sort: "VotesCount",
        },
        success: (res) => {
          this.rankIng = res.data;
        },
      });
    },
    // 点击跳转查看头像
    go_head_img(item) {
      this.$router.push({
        name: "head_img",
        query: {
          item: item,
        },
      });
    },
    // 点击跳转最新影片
    go_film() {
      this.$router.push({
        name: "watch_film",
        query: {
          item: this.newVideo,
        },
      });
    },
    // 修改地址
    alert_address(address) {
      switch (address) {
        case "FB":
          this.address = this.glader.fbUrl;
          this.alert = "FB";
          this.show = true;
          break;
        case "IG":
          this.address = this.glader.igUrl;
          this.alert = "IG";
          this.show = true;
          break;
      }
    },
    // 修改地址弹出框确定按钮
    define() {
      let data = {
        actID: this.glader.actID,
        vipId: this.num.idDecrypt,
        vipName: this.glader.vipName,
        gladeName: this.glader.gladeName,
        contact: this.glader.contact,
        email: this.glader.email,
        auditStatus: this.glader.auditStatus,
        auditReason: this.glader.auditReason,
        createDate: this.glader.createDate,
        createID: this.glader.createID,
        creator: this.glader.creator,
        fbUrl: this.glader.fbUrl,
        id: this.num.gladeid,
        igUrl: this.glader.igUrl,
        individualResume: this.glader.individualResume,
        modifier: this.glader.modifier,
        modifyDate: this.glader.modifyDate,
        modifyID: this.glader.modifyID,
        phoneNum: this.glader.phoneNum,
        photo1: this.glader.photo1,
        photo2: this.glader.photo2,
        remark: this.glader.remark,
        sort: this.glader.sort,
        status: this.glader.status,
        vidio: this.glader.vidio,
        isFinal: this.glader.isFinal,
        tagsCount: this.glader.tagsCount,
        votesCount: this.glader.votesCount,
        company: this.glader.company,
        sharesCount: this.glader.sharesCount,
        tagsSort: this.glader.tagsSort,
        votesSort: this.glader.votesSort,
        sortNO: this.glader.sortNO,
      };
      if (this.alert === "FB") {
        data.fbUrl = this.address;
      } else {
        data.igUrl = this.address;
      }
      this.$post({
        url: `/api/Act_glade/SaveGlade`,
        raw: true,
        data,
        success: (res) => {
          this.$toast(res.message);
          setTimeout(() => {
            this.get_activity();
          }, 1000);
        },
        tip: () => { },
      });
      this.show = false;
      this.address = this.alert = "";
    },
    cancel() {
      this.show = false;
      this.address = this.alert = "";
    },
    to_go() {
      this.$router.push({
        name: "personalfile",
        query: {
          actid: this.num.actid,
          vipid: this.num.idDecrypt,
          gladeid: this.num.gladeid,
        },
      });
    }
  },
};
</script>

<style scoped lang="less">
.box {}
.personal_box {
  .count {
    padding: 40px 32px;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_border {
        position: relative;
        width: 206px;
        height: 206px;
        border-radius: 50%;
        overflow: hidden;
        background: url("../../../assets/img/head_box.png") no-repeat;
        background-size: 100%;
        margin-right: 20px;
        .header_img {
          position: absolute;
          width: 164px;
          height: 164px;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .right {
        .name {
          width: 446px;
          height: 5vw;
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          line-height: 38px;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .activity {
          position: relative;
          background: url("../../../assets/img/active.png") no-repeat;
          background-size: 100%;
          height: 6vw;
          margin-bottom: 20px;
          .activity_count {
            width: 40vw;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            font-weight: 400;
            color: rgba(255, 205, 239, 0.7);
            line-height: 23px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .btn_box {
          display: flex;
          .btn {
            width: 133px;
            height: 52px;
            border-radius: 8px;
            border: 2px solid;
            font-size: 24px;
            font-weight: bold;
            line-height: 28px;
            margin: 0 8px;
            .text1 {
              background: linear-gradient(270deg, #3f738d 0%, #6cd8cf 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .text2 {
              background: linear-gradient(270deg, #4167d1 0%, #9fb8ea 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .text3 {
              background: linear-gradient(270deg, #a53a73 0%, #d682ac 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .btn1 {
            border-image: linear-gradient(270deg,
                rgba(68, 131, 152, 1),
                rgba(114, 213, 207, 1)) 2 2;
          }
          .btn2 {
            border-image: linear-gradient(270deg,
                rgba(65, 103, 209, 1),
                rgba(159, 184, 234, 1)) 2 2;
          }
          .btn3 {
            border-image: linear-gradient(270deg,
                rgba(165, 58, 115, 1),
                rgba(214, 130, 172, 1)) 2 2;
          }
        }
      }
    }
    .rank {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .rank_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          font-size: 30px;
          font-weight: bold;
          color: #ffffff;
          line-height: 35px;
          margin-bottom: 5px;
        }
        .bottom {
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }
    .address {
      margin-top: 40px;
      padding: 40px 0;
      border-top: 1px solid #353842;
      border-bottom: 1px solid #353842;
      .addressList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .address_box {
          font-size: 24px;
          font-weight: 300;
          color: #b8b8d2;
          line-height: 28px;
        }
        .btn {
          font-size: 24px;
          font-weight: 400;
          color: #62acf8;
          line-height: 28px;
          .img {
            width: 35px;
            height: 35px;
          }
        }
      }
      .alert_address {
        width: 90vw;
        height: 65vw;
        background-color: #191a23;
        .title {
          margin: 50px auto;
          text-align: center;
          font-size: 36px;
          font-weight: 550;
        }
        /deep/.van-cell-group {
          background: rgba(255, 255, 255, 0.1);
          .van-field {
            background: rgba(255, 255, 255, 0.1);
            .van-field__control::placeholder {
              color: rgba(255, 255, 255, 0.3);
            }
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .btn_box {
          margin-top: 100px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          .btn {
            width: 150px;
            height: 70px;
            font-size: 30px;
            font-weight: 500;
            border-radius: 20px;
          }
          .btn1 {
            background-color: red;
          }
          .btn2 {
            background-color: #666666;
          }
        }
      }
    }
    .introduce {
      margin-top: 32px;
      .title {
        margin-bottom: 20px;
        .img {
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }
        .text {
          font-size: 28px;
          font-weight: 400;
          color: #ffffff;
          line-height: 33px;
        }
      }
      .count {
        padding: 28px;
        min-height: 13vw;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 24px;
        .text {
          font-size: 28px;
          font-weight: 400;
          color: #d3d3d3;
          line-height: 40px;
          //overflow: hidden;
          //text-overflow: ellipsis;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //-webkit-line-clamp: 3;
        }
      }
    }
    .new_film {
      margin-top: 50px;
      .title {
        position: relative;
        margin-bottom: 32px;
        .text {
          font-size: 30px;
          font-weight: 400;
          color: #ffffff;
          line-height: 35px;
        }
        .img1 {
          position: absolute;
          width: 6.1vw;
          height: 6.1vw;
          top: -2.3vw;
          left: 12.7vw;
        }
        .img2 {
          position: absolute;
          width: 2.5vw;
          height: 2.5vw;
          bottom: -1vw;
          left: 12.5vw;
        }
      }
      .film {
        position: relative;
        width: 100%;
        height: 400px;
        border-radius: 15px;
        box-shadow: 0 0 20px 8px rgba(165, 203, 255, 0.32);
        .video {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
        .open {
          position: absolute;
          width: 80px;
          height: 80px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .new_img {
      margin-top: 50px;
      .title {
        position: relative;
        margin-bottom: 32px;
        .text {
          font-size: 30px;
          font-weight: 400;
          color: #ffffff;
          line-height: 35px;
        }
        .img1 {
          position: absolute;
          width: 6.1vw;
          height: 6.1vw;
          top: -0.3vw;
          left: 11.7vw;
        }
      }
      .img_box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .img {
          width: 200px;
          height: 200px;
          border-radius: 28px;
          background: #010228;
          box-shadow: 0 0 20px 8px rgba(255, 94, 244, 0.32);
          opacity: 1;
          border: 3px solid #d58fb7;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
