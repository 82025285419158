<template>
  <div class="enroll_box">
    <van-nav-bar
      title="活動報名"
      left-arrow
      @click-left="$router.go(-1)"
      fixed
      :border="false"
    />

    <div class="count">
      <van-cell-group>
        <div class="count_box">
          <div class="top">姓名</div>
          <van-field v-model="userName" clearable placeholder="請輸入姓名" />
        </div>

        <div class="count_box">
          <div class="top">佳麗暱稱</div>
          <van-field v-model="nickName" clearable placeholder="請輸入佳麗暱稱" maxlength="15" />
        </div>

        <div class="count_box">
          <div class="top">手機</div>
          <van-field v-model="phone" type="number" clearable placeholder="請輸入手機號碼" maxlength="10" />
        </div>

        <div class="count_box">
          <div class="top">Email</div>
          <van-field v-model="email" clearable placeholder="請輸入Email地址" />
        </div>

        <div class="count_box">
          <div class="top">Email驗證碼</div>
          <van-field
            v-model="emailCode"
            clearable
            placeholder="請輸入Email驗證碼"
          >
            <template #button>
              <van-button
                v-show="!countDone"
                :disabled="disabled"
                size="small"
                type="primary"
                @click="getCode()"
                >獲取驗證碼
              </van-button>
              <van-button
                v-show="countDone"
                disabled
                size="small"
                type="primary"
                >{{ count }}秒後重試</van-button
              >
            </template>
          </van-field>
        </div>

        <div class="count_box">
          <div class="top">公司</div>
          <van-field v-model="company" clearable placeholder="請輸入公司名稱" />
        </div>

        <div class="count_box">
          <div class="top">個人介紹</div>
          <van-field
            v-model="introduction"
            rows="5"
            autosize=""
            type="textarea"
            maxlength="500"
            placeholder="請輸入個人介紹"
            show-word-limit
          />
        </div>

        <div class="count_box">
          <div class="top">主頁展示照片</div>
          <van-uploader
            v-model="showImgList"
            :after-read="upload1"
            :max-count="1"
            :before-delete="deleteImg1"
          >
            <img src="../../../assets/img/upload_img.png" alt="" />
          </van-uploader>
        </div>

        <div class="count_box">
          <div class="top">個人更多照片</div>
          <van-uploader
            v-model="myImgList"
            :after-read="upload2"
            :max-count="3"
            :before-delete="deleteImg2"
          >
            <img src="../../../assets/img/upload_img.png" alt="" />
          </van-uploader>
        </div>

        <div class="count_box">
          <div class="top">展示影片</div>
          <div style="display: flex">
            <van-uploader
                v-model="showFilmList"
                accept="video/*"
                :after-read="upload3"
                :max-count="1"
                :upload-icon="videoimg"
                :before-delete="deleteImg3"
            >
              <img
                  v-if="!videoUrl"
                  src="../../../assets/img/upload_film.png"
                  alt=""
              />
              <template #preview-cover>
                <!-- id="upvideo" -->
                <!-- <video
                    v-show="videoUrl"
                    id="video"
                    class="video"
                    :src="httpPath + videoUrl + videoFile"
                ></video> -->
                <video
                    v-show="videoUrl"
                    id="video"
                    class="video"
                    :src="videoUrl"
                ></video>
                <!--	            <canvas v-show="videoUrl" id="canvas"></canvas>-->
              </template>
            </van-uploader>
          </div>
          <div>建議上傳90秒內影片</div>
        </div>

        <!--	      <div class="count_box">-->
        <!--		      <div class="top">上传影片封面图</div>-->
        <!--		      <van-uploader v-model="fileImg" :after-read="upload4" :max-count="1">-->
        <!--			      <img src="../../../assets/img/upload_img.png" alt="" />-->
        <!--		      </van-uploader>-->
        <!--	      </div>-->
      </van-cell-group>
    </div>
    <div class="btn_box">
      <van-button
        :loading="loading"
        class="defined"
        loading-text="加載中..."
        @click="define()"
        >送出審核</van-button
      >
    </div>
    <div class="real-time">
      <img src="../../../assets/img/information.png" alt="" />
    </div>
  </div>
</template>

<script>
import ImageCompressor from "js-image-compressor/src";
import {AwsUpdate} from "@/utils/upload";

export default {
  name: "enroll",
  created() {
    window.scrollTo(0, 0);
    this.num = JSON.parse(localStorage.getItem("taigu_useres"));
  },
  data() {
    return {
      loading: false,
      num: {},
      countDone: false,
      count: 60,
      timer: null,
      userName: "", // 姓名
      nickName: "", // 昵称
      phone: "", // 联系方式
      email: "", // Email
      emailCode: "", // Email 验证码
      company: "", // 公司
      introduction: "", // 个人介绍
      showImgList: [], // 主页展示照片
      myImgList: [], // 个人更多照片
      showFilmList: [], // 展示影片
      photo1: [], // 向后端发送的主页照片路径
      photo2: [], // 向后端发送的个人照片路径
      film1: [], // 向后端发送的影片路径
      // photo3: [], // 向后端发送的影片封面图路径
      videoFile: "", //视频后缀
      videoUrl: "", //视频路径
      fileImg: [], // 视频封面图
      videoimg: require("@/assets/img/upload_film.png"),
      imgsrc: "",
      disabled: false,
    };
  },
  methods: {
    // 删除上传的图片
    deleteImg1(file, detail) {
      this.photo1.splice(detail.index, 1);
      this.showImgList.splice(detail.index, 1);
    },
    deleteImg2(file, detail) {
      this.photo2.splice(detail.index, 1);
      this.myImgList.splice(detail.index, 1);
    },
    deleteImg3(file, detail) {
      this.film1.splice(detail.index, 1);
      this.showFilmList.splice(detail.index, 1);
    },
    // 获取邮箱验证码
    getCode() {
      this.disabled = true
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "發送中……",
      });
      this.$post({
        url: "/api/Act_glade/getEmailVerificationCode",
        data: {
          email: this.email,
        },
        success: (res) => {
          this.downTime();
          this.countDone = true;
          this.$toast.clear()
          return this.$toast("驗證碼已發送");
        },
        tips: () => {
          this.$toast.clear()
          this.$toast("郵箱錯誤");
          this.clearTime();
          this.countDone = false;
          this.disabled = false;
        },
      });
    },
    // 开启倒计时
    downTime() {
      this.timer = setInterval(() => {
        if (this.count <= 0) {
          this.clearTime();
          this.count = 60;
          this.countDone = false;
          this.disabled = false;
        } else {
          this.count--;
        }
      }, 1000);
    },
    // 清除倒计时
    clearTime() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 上传主页图片
    upload1(file) {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "上傳中……",
      });
      let num = 1;
      if (file.file.size / 1024 < 5210) {
        /*let formData = new FormData();
        formData.append("fileInput", file.file);
        this.$post({
          url: "/api/Act_votes/Upload",
          data: formData,
          upload: true,
          success: (res) => {
            this.$toast.clear();
            this.$toast(res.message);
            this.photo1.push(res.data + file.file.name);
          },
          tip: () => {},
        });*/

        AwsUpdate(file.file)
          .then(res => {
            this.$toast.clear()
            this.$toast('文件上傳成功')
            this.photo1.push(res.Location)
          })
          .catch(rej => {
            this.$toast.clear()
            this.$toast('文件上傳失敗')
          })
      } else {
        this.compress(file, num);
      }
    },
    // 上传个人图片
    upload2(file) {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "上傳中……",
      });
      let num = 2;
      if (file.file.size / 1024 < 5210) {
        /*let formData = new FormData();
        formData.append("fileInput", file.file);
        this.$post({
          url: "/api/Act_votes/Upload",
          data: formData,
          upload: true,
          success: (res) => {
            this.$toast.clear();
            this.$toast(res.message);
            this.photo2.push(res.data + file.file.name);
          },
          tip: () => {},
        });*/

        AwsUpdate(file.file)
          .then(res => {
            this.$toast.clear()
            this.$toast('文件上傳成功')
            this.photo2.push(res.Location)
          })
          .catch(rej => {
            this.$toast.clear()
            this.$toast('文件上傳失敗')
          })
      } else {
        this.compress(file, num);
      }
    },
    // 上传影片
    upload3(file) {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "上傳中……",
      });
      /*let formData = new FormData();
      formData.append("fileInput", file.file);
      this.$post({
        url: "/api/Act_votes/Upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.$toast.clear();
          this.$toast(res.message);
          // this.videoUrl = this.path + res.data + file.file.name;
          this.videoUrl = res.data;
          this.videoFile = file.file.name;
          this.film1.push(res.data + file.file.name);
        },
        tip: () => {},
      });*/

      AwsUpdate(file.file)
        .then(res => {
          this.$toast.clear()
          this.$toast('文件上傳成功')
          this.videoUrl = res.Location;
          this.videoFile = file.file.name;
          this.film1.push(res.Location);
        })
        .catch(rej => {
          this.$toast.clear()
          this.$toast('文件上傳失敗')
        })
    },
    compress(file, num) {
      let options = {
        file: file.file,
        quality: 0.4,
        mimeType: "image/jpeg",
        maxWidth: 5000,
        maxHeight: 5000,
        minWidth: 50,
        minHeight: 50,
        convertSize: Infinity,
        loose: true,
        redressOrientation: true,
        beforeCompress: (res) => {
          console.log(res);
        },
        success: (res) => {
          let file = res;
          switch (num) {
            case 1:
              this.upload1({
                file,
              });
              break;
            case 2:
              this.upload2({
                file,
              });
              break;
          }
        },
        error: (err) => {
          this.$toast.clear();
          console.log(err);
        },
      };
      new ImageCompressor(options);
    },
    // 发送审核
    define() {
      if (!this.userName.trim()) return this.$toast("請輸入姓名");
      if (!this.nickName.trim()) return this.$toast("請輸入昵稱");
      if (!this.phone.trim()) return this.$toast("請輸入手機號碼");
      if (!/^09[\d]{8}$/.test(this.phone.trim())) return this.$toast('手機號碼格式不正確')
      if (!this.email.trim()) return this.$toast("請輸入Email");
      if (!this.emailCode.trim()) return this.$toast("請輸入Email驗證碼");
      if (!this.company.trim()) return this.$toast("請輸入公司");
      if (!this.introduction.trim()) return this.$toast("請輸入個人介紹");
      if (this.photo1.length <= 0) return this.$toast("請上傳主頁展示照片");
      if (this.photo2.length <= 0) return this.$toast("請上傳個人更多照片");
      // if (this.film1.length <= 0) return this.$toast("請上傳影片");
      let num1 = "";
      let num2 = "";
      let num3 = "";
      if (this.photo1.length) {
        this.photo1.map((item) => {
          num1 = item;
        });
      } else {
        num1 = "";
      }
      if (this.photo2.length) {
        let arr = "";
        this.photo2.map((item) => {
          arr += item + ",";
        });
        num2 = arr.substring(0, arr.lastIndexOf(","));
      } else {
        num2 = "";
      }
      if (this.film1.length) {
        this.film1.map((item) => {
          num3 += item;
        });
      } else {
        num3 = "";
      }
      this.loading = true;
      let data = {
        actID: this.num.actid,
        vipId: this.num.idDecrypt,
        vipName: this.userName,
        gladeName: this.nickName,
        contact: this.phone,
        email: this.email,
        auditStatus: "",
        auditReason: "",
        createDate: "",
        createID: "",
        creator: "",
        fbUrl: "",
        id: 0,
        igUrl: "",
        individualResume: this.introduction,
        modifier: "",
        modifyDate: "",
        modifyID: "",
        phoneNum: "",
        photo1: num1,
        photo2: num2,
        remark: "",
        sort: 0,
        status: 0,
        vidio: num3,
        isFinal: 0,
        tagsCount: 0,
        votesCount: 0,
        company: this.company,
        sharesCount: 0,
        tagsSort: 0,
        votesSort: 0,
        sortNO: 0,
      };
      this.$post({
        url: `/api/Act_glade/SaveGlade?Code=${this.emailCode}`,
        raw: true,
        data,
        success: (res) => {
          this.$toast("您的報名資料已送出，審核結果將以郵件通知");
          this.loading = false;
          setTimeout(() => {
            this.$router.back();
          }, 1500);
        },
        tip: () => {
          this.loading = false;
        },
      });
    }
  },
};
</script>

<style scoped lang="less">
.box {
}
.enroll_box {
  //padding-bottom: 150px;
  .count {
    padding: 0 32px;
    /deep/.van-hairline--top-bottom::after {
      border: none;
    }
    .van-cell-group {
      background-color: rgba(0, 0, 0, 0);
      .count_box {
        margin-top: 40px;
        /deep/ .van-uploader__upload {
          background-color: transparent;
          .van-icon__image {
            width: 25vw;
            height: 25vw;
            margin: 41px 0 0 43px;
          }
        }
        .top {
          font-size: 28px;
          font-weight: 400;
          color: #b8b8d2;
          line-height: 33px;
          margin-bottom: 20px;
        }
      }
      .video {
        width: 25vw;
        height: 25vw;
      }
    }
  }
  .btn_box {
    margin-top: 10.7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .defined {
      width: 550px;
      height: 92px;
      background: linear-gradient(180deg, #4e9cda 0%, #29487b 100%);
      border-radius: 46px 46px 46px 46px;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
    }
    /deep/.van-button--default {
      border: none;
    }
  }
}
/*修改验证码按钮样式*/
/deep/.van-field__button {
  display: flex;
  .van-button--primary {
    color: #edc24a;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }
  .van-button--small {
    padding: 0;
    height: 100%;
  }
}
/*修改输入框样式*/
/deep/.van-field {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  .van-field__control::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .van-field__control {
    color: #ffffff;
  }
}
/*修改上传图片样式*/
/deep/.van-uploader {
  .van-uploader__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .van-uploader__preview {
      width: 25vw;
      height: 25vw;
      margin: 10px;
      .van-uploader__file {
        width: 25vw;
        height: 25vw;
      }
      .van-uploader__preview-image {
        width: 25vw;
        height: 25vw;
      }
      .van-uploader__preview-delete {
        border-radius: 50%;
        top: 8px;
        right: 8px;
        width: 36px;
        height: 36px;
        .van-uploader__preview-delete-icon:before {
          font-size: 6vw;
        }
      }
    }
    .van-uploader__input-wrapper {
      width: 25vw;
      height: 25vw;
      margin: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
#canvas {
  position: absolute;
  background: aliceblue;
  width: 25vw;
  height: 25vw;
  left: 0;
}
.real-time {
  width: 100%;
  text-align: center;
  margin: 80px 0;
  img {
    height: 30px;
  }
}
</style>
