<template>
  <div class="activity_box">
	  <van-nav-bar title="活動" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
    <div class="msg">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: "un_activity",
  created() {
  	this.msg = this.$router.history.current.query.msg
  },
  data() {
    return {
    	msg: ''
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.box {}

.activity_box {
	width: 100%;
	min-height: 100vh;
	position: relative;

	.msg {
		font-size: 36px;
		color: rgba(255,255,255,0.3);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}


</style>
