var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxs"},[_c('div',{staticClass:"list"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"","immediate-check":false},on:{"load":_vm.onload},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',[_c('div',{staticClass:"list_item"},[(_vm.list.length > 2)?_c('div',{staticClass:"item_left",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  path: '/personalfile',
                  query: {
                    actid: _vm.list[2].actID,
                    vipid: _vm.list[2].vipId,
                    gladeid: _vm.list[2].id,
                  }})}}},[_c('div',{staticClass:"left_head"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImage(_vm.list[2].photo1)),expression:"getImage(list[2].photo1)"}],staticClass:"head_img"})]),_c('div',{staticClass:"left_name"},[_c('div',{staticClass:"name two"},[_vm._v(_vm._s(_vm.list[2].gladeName))]),(_vm.list[2].isNewState == 1)?_c('div',{staticClass:"name_logo"},[_vm._v("NEW")]):_vm._e()]),_c('div',{staticClass:"left_num"},[_c('div',{staticClass:"num_img"},[_c('img',{attrs:{"src":require("@/assets/img/beauty04.png")}})]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.list[2].tagsCount))])]),_c('div',{staticClass:"left_img",on:{"click":function($event){$event.stopPropagation();return _vm.thumb(_vm.list[2])}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.isvote(_vm.list[2].id) ? _vm.thumbimg : _vm.no_thumb),expression:"isvote(list[2].id) ? thumbimg : no_thumb"}],attrs:{"alt":""}})])]):_vm._e(),(_vm.list.length > 0)?_c('div',{staticClass:"item_top",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  path: '/personalfile',
                  query: {
                    actid: _vm.list[0].actID,
                    vipid: _vm.list[0].vipId,
                    gladeid: _vm.list[0].id,
                  }})}}},[_c('div',{staticClass:"top_head"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImage(_vm.list[0].photo1)),expression:"getImage(list[0].photo1)"}],staticClass:"head_img"})]),_c('div',{staticClass:"top_name"},[_c('div',{staticClass:"name two"},[_vm._v(_vm._s(_vm.list[0].gladeName))]),(_vm.list[0].isNewState == 1)?_c('div',{staticClass:"name_logo"},[_vm._v("NEW")]):_vm._e()]),_c('div',{staticClass:"top_num"},[_c('div',{staticClass:"num_img"},[_c('img',{attrs:{"src":require("@/assets/img/beauty04.png")}})]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.list[0].tagsCount))])]),_c('div',{staticClass:"top_img",on:{"click":function($event){$event.stopPropagation();return _vm.thumb(_vm.list[0])}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.isvote(_vm.list[0].id) ? _vm.thumbimg : _vm.no_thumb),expression:"isvote(list[0].id) ? thumbimg : no_thumb"}],attrs:{"alt":""}})])]):_vm._e(),(_vm.list.length > 1)?_c('div',{staticClass:"item_right",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  path: '/personalfile',
                  query: {
                    actid: _vm.list[1].actID,
                    vipid: _vm.list[1].vipId,
                    gladeid: _vm.list[1].id,
                  }})}}},[_c('div',{staticClass:"right_head"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImage(_vm.list[1].photo1)),expression:"getImage(list[1].photo1)"}],staticClass:"head_img"})]),_c('div',{staticClass:"right_name"},[_c('div',{staticClass:"name two"},[_vm._v(_vm._s(_vm.list[1].gladeName))]),(_vm.list[1].isNewState == 1)?_c('div',{staticClass:"name_logo"},[_vm._v("NEW")]):_vm._e()]),_c('div',{staticClass:"right_num"},[_c('div',{staticClass:"num_img"},[_c('img',{attrs:{"src":require("@/assets/img/beauty04.png")}})]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.list[1].tagsCount))])]),_c('div',{staticClass:"right_img",on:{"click":function($event){$event.stopPropagation();return _vm.thumb(_vm.list[1])}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.isvote(_vm.list[1].id) ? _vm.thumbimg : _vm.no_thumb),expression:"isvote(list[1].id) ? thumbimg : no_thumb"}],attrs:{"alt":""}})])]):_vm._e()]),_c('div',{staticClass:"check"}),_c('div',{staticClass:"cell"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"cells",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  path: 'personalfile',
                  query: {
                    actid: item.actID,
                    vipid: item.vipId,
                    gladeid: item.id,
                  }})}}},[(index > 2)?_c('div',{staticClass:"cell_item"},[_c('div',{staticClass:"cell_flex"},[_c('div',{staticClass:"cell_num"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',{staticClass:"cell_head"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImage(item.photo1)),expression:"getImage(item.photo1)"}]})]),_c('div',{staticClass:"cell_name"},[_c('div',{staticClass:"item_name"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.gladeName))]),(item.isNewState == 1)?_c('div',{staticClass:"name_logo"},[_vm._v(" NEW ")]):_vm._e()]),_c('div',{staticClass:"item_num"},[_c('div',{staticClass:"num_img"},[_c('img',{attrs:{"src":require("@/assets/img/beauty04.png")}})]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(item.tagsCount))])])])]),(index > 2)?_c('div',{staticClass:"cell_zan",on:{"click":function($event){$event.stopPropagation();return _vm.thumb(item)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.isvote(item.id) ? _vm.thumbimg : _vm.no_thumb),expression:"isvote(item.id) ? thumbimg : no_thumb"}],attrs:{"alt":""}})]):_vm._e()]):_vm._e()])}),0)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }