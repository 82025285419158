import axios from "axios";
import JSONBIG from "json-bigint";
import qs from "qs";
import Vant, { Lazyload, Toast, Locale } from "vant";
import VConsole from "vconsole";
import zhTW from 'vant/es/locale/lang/zh-TW';
Locale.use('zh-TW', zhTW);
export default {
  install: Vue => {
    Vue.use(Vant);
    Vue.use(Lazyload, {
      lazyComponent: true,
    });
    Toast.setDefaultOptions({ forbidClick: true });
    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    // js返回进度丢失
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({
          storeAsString: true
        })
        const res = json.parse(data)
        return res
      }
    ]
    axios.defaults.timeout = 3000000; //请求超时
    // const no_login_code = -1; //未登录返回的状态码
    // const success_code = 1; //成功返回的状态码
    // const err_code = 0; //失败返回的状态码
    //设置开发环境域名
    if (process.env.VUE_APP_IS_LOG == 1) {
      const vConsole = new VConsole()
      Vue.use(vConsole)
    }
    axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
    console.log(`%c api_path ${process.env.VUE_APP_BASE_API}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')

    if (process.env.VUE_APP_WEB_PATH) {
      console.log(`%c web_path ${process.env.VUE_APP_WEB_PATH}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')
    }
    Vue.prototype.httpPath = process.env.VUE_APP_WEB_PATH;
    if (process.env.VUE_APP_WS_PATH) {
      console.log(`%c ws_path ${process.env.VUE_APP_WS_PATH}`, 'background:#328ee6;padding:0 5px 1px 0;border-radius: 3px 0 0 3px;  color: #ffffff')
    }
    Vue.prototype.wsPath = process.env.VUE_APP_WS_PATH;
    Vue.prototype.$get = params => {
      axios
        .get(params.url, {
          params: Object.assign({}, params.data)
        })
        .then(res => {
          if (res.data.status) {
            if (params.success) params.success(res.data);
          } else {
            if (params.tip) {
              params.tip(res.data)
              Toast(res.data.message);
            } else if (params.tips) {
              // 无轻提示
              params.tips(res.data)
            }
          }
        }).catch(err => {
          console.log('服务器请求失败', err.response)
          if (err.response.status == '404') {
            Toast('接口不存在');
          } else if (err.response.status == '500') {
            Toast(err.response.data.message);
          }
        })
    }

    Vue.prototype.$post = params => {
      let $axios;
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };
      if (params.upload) {
        config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        $axios = axios.post(params.url, params.data, config)
      } else if (params.noToken) {
        let data = qs.stringify(Object.assign({}, params.data))
        $axios = axios.post(params.url, data)
      } else if (params.raw) {
        config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        $axios = axios.post(params.url, params.data, config)
      } else {
        let data = qs.stringify(Object.assign({}, params.data))
        $axios = axios.post(params.url, data, config)
      }

      $axios
        .then(res => {
          if (res.data.status) {
            if (params.success) params.success(res.data);
          } else {
            if (params.tip) {
              params.tip('val')
              Toast(res.data.message);
            } else if (params.tips) {
              // 无轻提示
              params.tips('vals')
            }
          }
        }).catch(err => {
          if (err.response.status == '404') {
            Toast('接口不存在');
          } else if (err.response.status == '500') {
            Toast(err.response.data.message);
          }
        })
    }

  }
}