<template>
  <div>
    <div class="box" v-if="type">
      <van-nav-bar
        title="排行榜"
        @click-left="$router.go(-1)"
        fixed
        :border="false"
      >
        <template #right>
          <div
            class="right"
            @click="$router.push('/personal')"
            v-if="vipid && gladeid"
          >
            <div class="right_img">
              <img src="~@/assets/img/beauty03.png" />
            </div>
            <div class="right_text">個人中心</div>
          </div>
        </template>
      </van-nav-bar>
      <div class="tab">
        <van-tabs v-model="active" @change="tabChange">
          <van-tab v-for="(item, index) in list" :key="index">
            <template #title>
              <div :class="active == item.name ? 'title' : 'titles'">
                <div class="title_img"><img v-lazy="item.img" /></div>
                <div class="title_text">{{ item.text }}</div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </div>
      <div v-if="active == 0">
        <Vote :info="info" :id="id"></Vote>
      </div>
      <div v-if="active == 1">
        <Recommend :info="info"></Recommend>
      </div>
      <div v-if="active == 2">
        <Thumb :info="info" :id="id"></Thumb>
      </div>
      <div class="real-time">
        <img src="../../../assets/img/information.png" alt="" />
      </div>
    </div>
    <div class="boxss" v-if="twotype">
      <van-nav-bar
        title=" 活動"
        @click-left="$router.go(-1)"
        fixed
        :border="false"
      />
      <div class="times">直播時間：{{ info.prizeTime }}</div>
      <div class="new_film">
        <div class="film" @click="gofilm">
          <img class="film_img" v-lazy="getImage(info.bannerImg)" alt="" />
          <img class="open" src="../../../assets/img/play.png" alt="" />
        </div>
      </div>
      <img class="rank_img" src="~@/assets/img/award02.png" />
      <div class="list">
        <div class="line">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="沒有更多了"
            @load="onload"
          >
            <div
              class="list_item"
              v-for="(item, index) in order"
              :key="index"
              @click="
                $router.push({
                  path: 'personalfile',
                  query: {
                    actid: item.actID,
                    vipid: item.vipId,
                    gladeid: item.id,
                    fieldstatus: 1,
                  },
                })
              "
            >
              <div class="item_num">{{ index + 1 }}.</div>
              <div class="item_flex">
                <img class="flex_left" v-lazy="getImage(item.photo1)" />
                <div class="flex_right">
                  <div class="right_name">
                    <div class="name">{{ item.vipName }}</div>
                    <div class="new_logo" v-if="item.isNewState == 1">NEW</div>
                  </div>
                  <div class="right_num">
                    <img class="flex_left" src="~@/assets/img/shoucang.png" />
                    <div class="num">{{ item.votesCount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div class="real-time">
          <img src="../../../assets/img/information.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Recommend from "@/components/recommend.vue";
import Thumb from "@/components/thumb.vue";
import Vote from "@/components/vote.vue";
import { getImage } from "@/utils/upload";

export default {
  components: {
    Recommend,
    Vote,
    Thumb,
  },

  data() {
    return {
      active: 0,
      list: [
        {
          img: require("@/assets/img/shoucang.png"),
          name: "0",
          text: "選票",
        },
        {
          img: require("@/assets/img/beauty02.png"),
          name: "1",
          text: "推薦",
        },
        {
          img: require("@/assets/img/beauty04.png"),
          name: "2",
          text: "按讚",
        },
      ],
      id: "",
      info: {},
      order: [],
      loading: false,
      finished: false,
      page: 1,
      gladeid: "", //佳丽id
      vipid: "", //会员id
      type: false,
      twotype: false,
    };
  },
  created() {
    const { type } = this.$route.query
    this.active = type ? Number(type) : 0

    window.scrollTo(0, 0);
    this.getinfo();
    this.$nextTick(_ => {
      let pick = JSON.parse(localStorage.getItem("taigu_user"));
      if (pick) {
        this.vipid = pick.idDecrypt;
      }
      let picks = JSON.parse(localStorage.getItem("taigu_useres"));
      if (picks) {
        this.gladeid = picks.gladeid;
      }
    })
  },
  methods: {
    getImage,
    gofilm() {
      window.location.href = this.info.prizeUrl;
    },
    onload() {
      var page = this.page++;
      this.$post({
        url: "/api/Act_glade/GetGladeRankByActid",
        data: {
          pageIndex: page,
          pagesize: 10,
          actid: this.info.id,
          sort: "VotesCount",
        },
        success: (res) => {
          this.$toast.clear();
          if (!res || res.data.length == 0) {
            this.finished = true;
          } else {
            var list = res.data;
            if (page == 1) {
              this.order = list;
            } else {
              for (var i in list) {
                this.order.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
    getinfo() {
      this.$get({
        url: "/api/Act_activity/GetActivity",
        data: {},
        success: (res) => {
          this.info = res.data;
          this.type =
            new Date().getTime() >=
              new Date(
                this.info.votingStartTime.replace(/-/g, "/")
              ).getTime() &&
            new Date().getTime() <
              new Date(this.info.finalStopTime.replace(/-/g, "/")).getTime();
          this.twotype =
            new Date().getTime() >
            new Date(this.info.finalStopTime.replace(/-/g, "/")).getTime();
          console.log(this.type, this.twotype);
        },
        tip: () => {},
      });
    },
    tabChange(name) {
      this.$router.push({
        path: '/beauty',
        query: {
          type: name
        }
      }).catch(() => {})
    }
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  min-height: 100vh;
  background: url("~@/assets/img/beauty01.png") no-repeat;
  background-size: 100% 100vh;
  background-color: #2d286a;

  .right {
    display: flex;
    align-items: center;

    .right_img {
      width: 36px;
      height: 36px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right_text {
      font-size: 26px;
      font-weight: 400;
      color: #f6838a;
      padding-top: 8px;
    }
  }

  .tab {
    padding-top: 260px;

    .title {
      display: flex;
      align-items: center;
      border: 3px solid #5c68de;
      border-radius: 36px 36px 36px 36px;
      padding: 10px 32px;

      .title_img {
        width: 32px;
        height: 32px;
        line-height: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title_text {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        padding-left: 15px;
      }
    }

    .titles {
      display: flex;
      align-items: center;
      border: 3px solid #a1959d;
      border-radius: 36px 36px 36px 36px;
      padding: 10px 32px;

      .title_img {
        width: 32px;
        height: 32px;
        line-height: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title_text {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        padding-left: 15px;
      }
    }

    /deep/ .van-tabs {
      .van-tabs__nav {
        background-color: transparent;
      }

      .van-tabs__line {
        width: 0;
      }
    }
  }
}

.boxss {
  width: 100%;
  min-height: 100vh;
  background: url("~@/assets/img/award01.png") no-repeat;
  background-size: 100% 100vh;
  position: relative;

  .times {
    display: flex;
    justify-content: center;
    padding-top: 180px;
    font-size: 26px;
    color: #fff;
  }

  .new_film {
    padding: 50px 30px 100px;

    .film {
      position: relative;
      width: 100%;
      height: 400px;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 0px 20px 8px rgba(165, 203, 255, 0.32);

      .film_img {
        width: 100%;
        height: 100%;
      }

      .open {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .rank_img {
    width: 496px;
    height: 106px;
    position: absolute;
    transform: translate(25%, -52%);
  }

  .list {
    padding: 0px 40px 30px;
    background: #262461;

    .line {
      padding: 100px 30px 0px;
      border-radius: 32px 32px 32px 32px;
      border: 4px solid #f8dbc1;

      .list_item {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: 3.7vw;
        border-bottom: 1px solid #373669;

        .item_num {
          font-size: 30px;
          font-weight: 550;
          color: #fd828f;
          padding-right: 20px;
        }

        .item_flex {
          display: flex;
          align-items: center;

          .flex_left {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .flex_right {
            padding-left: 20px;

            .right_name {
              display: flex;
              align-items: center;

              .name {
                font-size: 30px;
                font-weight: 550;
                color: #ffffff;
                padding-right: 15px;
              }

              .new_logo {
                background: #f98186;
                font-size: 15px;
                border-radius: 30px;
                padding: 4px 12px;
              }
            }

            .right_num {
              display: flex;
              align-items: center;
              padding-top: 10px;

              .flex_left {
                width: 25px;
                height: 25px;
                object-fit: cover;
              }

              .num {
                font-size: 24px;
                font-weight: 400;
                color: #fedd66;
                padding-left: 5px;
              }
            }
          }
        }
      }

      .list_item:last-child {
        border-bottom: none;
      }
    }
  }
}

.real-time {
  width: 100%;
  text-align: center;
  padding: 57px 0 40px 0;

  img {
    height: 30px;
  }
}
</style>
