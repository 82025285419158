<template>
  <div class="privacy_box">
    <van-nav-bar title="隱私權版權宣告" left-arrow @click-left="$router.go(-1)" fixed :border='false' />
    <div class="count">
      <div class="title">隱私權政策</div>
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
	created () {
		window.scrollTo(0, 0)
		this.get_options()
	},
  data() {
    return {
      content: ''
    }
  },
  methods: {
  	get_options() {
  		this.$get({
			  url: '/api/Act_options/GetOptions',
			  success: (res) => {
				  this.content = res.data.privacy
			  },
			  tip: () => { }
		  })
	  }
  }
}
</script>

<style scoped lang="less">
.box {}

.privacy_box {
  padding-bottom: 60px;

  .count {
    padding: 0 30px;
    background-color: #191A23;

    .title {
      margin-top: 60px;
      font-size: 36px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      margin-bottom: 36px;
    }

    .content {
      font-size: 26px;
      font-weight: 400;
      color: #D3D3D3;
      line-height: 42px;
    }
  }
}
</style>