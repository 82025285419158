<template>
  <div class="chat_box">
    <van-nav-bar :title="roomName" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
    <div class="content_box" ref="record">
      <div class="listcontent">
        <div class="item_loading" v-if="loading">
          <van-loading />
        </div>
        <div v-for="item in chatMessages" :key="item._id" class="item">
          <!-- <div class="timebox">{{item.createdAt}}</div> -->
          <div class="userbox" :class="item.author._id == userId ? 'author_my' : ''">
            <!-- 自己 -->
            <template v-if="item.author._id == userId">
              <template v-if="type == 1">
                <van-image class="user_img" data-type='1-会员' lazy-load :src="getImage(vipid_header)" v-if="vipid_header" />
                <van-image class="user_img" data-type='1-默认会员头像' lazy-load :src="getImage(header_obj.tovipid_header)" v-else />
              </template>
              <template v-else>
                <van-image class="user_img" data-type='1-佳丽' lazy-load :src="getImage(tovipid_header)" v-if="tovipid_header" />
                <van-image class="user_img" data-type='1-默认会员头像' lazy-load :src="getImage(header_obj.tovipid_header)" v-else />
              </template>
            </template>
            <!-- 佳丽 -->
            <template v-else>
              <template v-if="type == 2">
                <van-image class="user_img" data-type='2-佳丽' lazy-load :src="getImage(vipid_header)" v-if="vipid_header" />
                <van-image class="user_img" data-type='2-默认会员头像' lazy-load :src="getImage(header_obj.tovipid_header)" v-else />
              </template>
              <template v-else>
                <van-image class="user_img" data-type='2-佳丽' lazy-load :src="getImage(tovipid_header)" v-if="tovipid_header" />
                <van-image class="user_img" data-type='2-默认会员头像' lazy-load :src="getImage(header_obj.tovipid_header)" v-else />
              </template>
            </template>
            <div class="contentbox" @click="clickContentbox">
              <template v-if="item.image_file == 'null'">
                <div v-if='item.message.search(`<div class="img_content">`) > -1' v-html="item.message"></div>
                <p v-else class="msg_content">
                  {{ item.message }}
                </p>
              </template>
              <div class="img_content" v-else>
                <img v-lazy="item.image_file" alt="" />
              </div>
              <p class="time">
                {{ dayjs(item.createdAt).format("YYYY-DD-MM HH:MM") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input_box">
      <div class="input">
        <van-uploader v-model="showFilmList" :max-count="1" :preview-image="false" :after-read="upload_img">
          <img src="../../../assets/img/upload_left.png" alt="" />
        </van-uploader>
        <van-field v-model="messages" />
      </div>
      <img class="send" src="../../../assets/img/person12.png" @click="socketSendmsg()" />
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ImagePreview } from "vant"
import ImageCompressor from "js-image-compressor/src"
import { getImage } from "@/utils/upload";

export default {
  name: "chat_box",
  data() {
    return {
      room_id: this.$route.query.roomId,
      userId: "", //自己的 im id
      roomName: "聊天室", //聊天对象 name  this.$route.query.roomName
      type: this.$route.query.type, //1 当前用户发起会话  2 接收的会话
      vipid: this.$route.query.vipid, //当前用户id
      tovipid: this.$route.query.tovipid, //对方聊天id
      loading: false,
      chatMessages: [], //聊天信息
      messages: "", // 聊天输入框内容
      showFilmList: [],
      header_obj: {}, //默认头像
      vipid_header: "",
      tovipid_header: "",
      userinfo: {},
    }
  },
  sockets: {
    connect: function () {
      console.log("connect连接")
      this.join_class()
    },
    disconnect: function () {
      console.log("断开连接")
    },
    reconnect: function () {
      console.log("重新连接")
    },
    // 接受消息
    NEW_MESSAGE(message) {
      console.log("NEW_MESSAGE", message)
      if (Object.prototype.toString.call(message) === "[object Object]") {
        this.chatMessages.push(message)
        this.$nextTick(() => {
          this.$refs.record.scrollTop = this.$refs.record.scrollHeight
        })
      }
    },
    // 消息被阅读
    MESSAGE_READED(message) {
      console.log("MESSAGE_READED", message)
    },
  },
  methods: {
    getImage,
    // 预览图片
    watch_img(img) {
      ImagePreview([img])
    },
    // 滑动预览图片
    onChange(index) {
      this.index = index + 1
    },
    // 发送图片
    upload_img(file) {
      this.upload(file)

      /*this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.showFilmList = [];
      this.sendImageMessage(file.content, file.file.name);
      return;
      let formData = new FormData();
      formData.append("file", file.file);
      axios
        .post(
          `${process.env.VUE_APP_WS_PATH}/api/chats/upload-image-user`,
          formData
        )
        .then((res) => {
          if (res.data.error) {
            this.$toast(res.data.message);
          } else {
            // this.sendImageMessage(res.data.data, file.file.name)
          }
        });*/
    },
    // 加入房间
    join_class() {
      this.$socket.emit("join_group", {
        groupId: this.room_id, //房间 id
      })
    },
    // 發送消息
    socketSendmsg() {
      if (!this.messages) return this.$toast("不能發送空白訊息")
      this.$socket.emit("SEND_NEW_MESSAGE", {
        groupId: this.room_id, //房间 id
        // message: this.messages,
        message: this.messages,
        author: {
          _id: this.userId, // 发送人 id
          avatar: "", // 头像 ?
          name: ``, // 发送人名称
        },
      })
      this.messages = ""
      this.$nextTick(() => {
        this.$refs.record.scrollTop = this.$refs.record.scrollHeight
      })
      this.postapimcg()
    },
    // 发送图片
    sendImageMessage(image_file, image_filename) {
      let newMessage = {
        groupId: this.room_id, // 房间 id
        message: "", // 消息
        image_file, // 图片 base64
        image_filename, // 图片 名称
        author: {
          _id: this.userId, // 发送人 id
          avatar: "", // (Image File Path)
          name: "", // 发送人名称
        },
      }
      this.$toast.clear()
      this.$socket.emit("base64_file", newMessage)
    },
    // 推送app消息
    postapimcg() {
      let id = this.userinfo.idDecrypt == this.vipid ? this.tovipid : this.vipid
      axios.post(process.env.VUE_APP_NOTIFICATION_PATH, {
          Account: "casaloma",
          Guid: "Casaloma6214.",
          ViaAccount: "MessageAD",
          // "Receivers": "M998_F623000002",
          Receivers: `M998_${id}`,
          Title: "你有新訊息",
          ExtraData: '{"cmd":"200", "functionNo":"F605"}',
        }).then((res) => {
          console.log(res)
          // if (res.data.error) {
          //   this.$toast(res.data.message)
          // } else {
          // }
        })
    },
    // 阅读消息
    MAKE_READ(chatId) {
      this.$socket.emit("MAKE_READ", {
        chatId, // 消息 id
        groupId: this.room_id, // 房间 id
        author: {
          _id: this.userId, // 阅读人 id
        },
      })
    },
    //聊天记录
    getchatlist() {
      this.loading = true
      axios.get(`${process.env.VUE_APP_WS_PATH}/api/chat/${this.room_id}`).then((res) => {
        console.log("聊天记录", res)
        if (res.data.error) {
          this.$toast(res.data.message)
        } else {
          this.chatMessages = res.data.payload.chatMessages
          if (this.chatMessages.length >= 0) {
            this.loading = false
            this.$nextTick(() => {
              this.$refs.record.scrollTop = this.$refs.record.scrollHeight
            })
          }
        }
      })
    },
    // 信息
    GetGladeInfo() {
      this.$get({
        url: "/api/Act_glade/GetGladeByvipid",
        data: {
          actid: this.userinfo.actid,
          vipid: this.vipid,
        },
        success: (res) => {
          this.vipid_header = res.data.photo1
        },
      })

      this.$get({
        url: "/api/Act_glade/GetGladeByvipid",
        data: {
          actid: this.userinfo.actid,
          vipid: this.tovipid,
        },
        success: (res) => {
          this.tovipid_header = res.data.photo1
        },
      })
    },
    // 上传图片
    upload(file) {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "上傳中……",
      })
      if (file.file.size / 1024 < 5210) {
        let formData = new FormData()
        formData.append("fileInput", file.file)
        this.$post({
          url: "/api/Act_state/Upload",
          data: formData,
          upload: true,
          success: (res) => {
            this.$toast.clear()
            this.showFilmList = []
            this.socketSendImageMsg(res.data, file.file.name)
          },
          tip: () => {},
        })
      } else {
        this.compress(file)
      }
    },
    // 压缩图片
    compress(file) {
      let options = {
        file: file.file,
        quality: 0.4,
        mimeType: "image/jpeg",
        maxWidth: 5000,
        maxHeight: 5000,
        minWidth: 50,
        minHeight: 50,
        convertSize: Infinity,
        loose: true,
        redressOrientation: true,
        beforeCompress: (res) => {
          console.log(res)
        },
        success: (res) => {
          let file = res
          this.upload({
            file,
          })
        },
        error: (err) => {
          this.$toast.clear()
          console.log(err)
        },
      }
      new ImageCompressor(options)
    },
    // 发送图片信息
    socketSendImageMsg(url, name) {
      this.$socket.emit("SEND_NEW_MESSAGE", {
        groupId: this.room_id, //房间 id
        message: `<div class="img_content">
                    <img src="${this.httpPath + url + name}" loading="lazy" />
                  </div>`,
        author: {
          _id: this.userId, // 发送人 id
          avatar: "", // 头像 ?
          name: ``, // 发送人名称
        },
      })
      this.$toast.clear()
      this.$nextTick(() => {
        this.$refs.record.scrollTop = this.$refs.record.scrollHeight
      })
    },
    clickContentbox(e) {
      const target = e.target
      if (target.matches("img")) {
        const src = target.src
        this.watch_img(src)
      }
    },
  },
  created() {
    if (localStorage.getItem("options")) {
      this.header_obj = JSON.parse(localStorage.getItem("options"))
    }
    this.userinfo = JSON.parse(localStorage.getItem("taigu_useres"))
    if (localStorage.getItem("jwt_im_userId")) {
      this.userId = localStorage.getItem("jwt_im_userId")
      this.$socket.open()
      this.GetGladeInfo()
      this.getchatlist()
    }
  },
  beforeDestroy() {
    //退出页面关闭socket
    this.$socket.close()
  },
}
</script>

<style scoped lang="less">
@iosheight: constant(safe-area-inset-bottom);
.chat_box {
  min-height: 100vh;
  .content_box {
    overflow: scroll;
    height: calc(100vh - 32.2vw);
    position: relative;
    .listcontent {
      width: 100%;
      height: auto;
      background: #191a23;
      padding: 40px 24px 0 24px;
      .item_loading {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item {
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 30px;
        .timebox {
          width: 100%;
          text-align: center;
          font-size: 24px;
          padding: 10px 0 20px;
        }
        .userbox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .user_img {
            width: 70px;
            height: 70px;
            border-radius: 19px;
            /deep/.van-image__img {
              border-radius: 10px;
            }
          }
          .contentbox {
            max-width: 76%;
            padding: 0 0 0 28px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            /deep/ .msg_content {
              max-width: 100%;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 20px;
              padding: 16px 16px 16px 14px;
              color: #ffffff;
              font-size: 28px;
              font-weight: 500;
              line-height: 1.4;
            }
            /deep/ .img_content {
              max-width: 100%;
              display: flex;
              > img {
                display: inline-table;
                max-width: 100%;
                max-height: 400px;
                border-radius: 0;
              }
            }
            .time {
              margin: 10px 0;
              font-size: 24px;
            }
          }
        }
        .author_my {
          flex-direction: row-reverse;
          .contentbox {
            padding: 0 28px 0 0;
            align-items: flex-end;
          }
        }
      }
    }
  }
  // 文字输入框区域
  .input_box {
    bottom: 0;
    width: 100%;
    height: 20vw;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    background: #191a23;
    padding: 1.6vw 4.3vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .input {
      width: 582px;
      height: 88px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 24px;
      border: 2px solid rgba(251, 249, 255, 0.15);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      img {
        width: 80px;
      }
      /deep/.van-field {
        background: rgba(255, 255, 255, 0);
        //border-radius: 16px;
        .van-field__control::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
        .van-field__control {
          color: #ffffff;
        }
      }
      /deep/.van-cell::after {
        border: none;
      }
    }
    .send {
      width: 88px;
      height: 88px;
    }
  }
}
</style>
