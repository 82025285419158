<template>
  <div>
    <van-nav-bar title="聊天列表" left-arrow @click-left="$router.go(-1)" fixed :border="false" />
    <van-list v-model="loading" :finished="finished" finished-text="沒有更多了" @load="onLoad" :offset="0">
      <div class="concat" v-for="val in list" :key="val.id">
        <div class="head">
          <!-- 只显示会员头像 -->
          <van-image v-if="getPhoto(val)" class="user_img" lazy-load :src="getImage(getPhoto(val))" />
          <van-image v-else class="user_img" lazy-load :src="getImage(header_obj.tovipid_header)" />
          <!-- v-if="userinfo.idDecrypt == val.vipId" -->
          <!-- <van-image class="user_img" lazy-load :src="`${httpPath}${header_obj.vipid_header}`" v-else /> -->
        </div>
        <div class="chat" @click="chatroom(val)">
          <div class="up">
            <div class="name" v-if="userinfo.idDecrypt == val.vipId">{{ val.toVipName || val.roomName }}</div>
            <div class="name" v-else>{{ val.vipName || val.roomName }}</div>
            <!-- <div class="time">2022-07-10 12:30</div> -->
          </div>
          <div class="down">
            <!-- 嗨嗨~這是你送給我的禮物嗎？非常感謝你~我非常嗨嗨~這是你送給我的禮物嗎？非常感謝你~我非常 -->
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { getImage } from "@/utils/upload";

export default {
  data() {
    return {
      header_obj: {},
      userinfo: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  methods: {
    getImage,
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/api/Act_chatroom/GetChatroomListByVipid",
        data: {
          vipid: this.userinfo.idDecrypt,
          pageIndex: page,
          pagesize: 15,
        },
        success: (res) => {
          if (!res || res.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
    chatroom(data) {
      let type = this.userinfo.idDecrypt == data.vipId ? 1 : 2
      this.$router.push({
        path: "/chat_box",
        query: {
          roomId: data.roomId,
          roomName: data.roomName,
          vipid: data.vipId,
          tovipid: data.toVipId,
          type,
        },
      })
    },
    getPhoto(val) {
      return val.vipId === this.userinfo.idDecrypt ? val.to_photo1 : val.photo1
    }
  },
  created() {
    if (localStorage.getItem("options")) {
      this.header_obj = JSON.parse(localStorage.getItem("options"))
    }
    this.userinfo = JSON.parse(localStorage.getItem("taigu_useres"))
  },
}
</script>
<style scoped lang="less">
.box {
}
.concat {
  padding: 36px 32px;
  display: flex;
  .head {
    width: 88px;
    height: 88px;
    border-radius: 30%;
    .user_img {
      width: 100%;
      height: 100%;
      /deep/.van-image__img {
        border-radius: 10px;
      }
    }
  }
  .chat {
    padding-left: 32px;
    .up {
      display: flex;
      padding-bottom: 10px;
      justify-content: space-between;
      .name {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
      }
      .time {
        font-size: 22px;
        color: #ffffff;
      }
    }
    .down {
      width: 566px;
      color: #adb5bd;
      font-size: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
