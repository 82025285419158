import Vue from 'vue'
import router from 'vue-router'
import routes from './modules/pages'
import children from './modules/tabbar'
import components from './modules/components'

Vue.use(router)

Object.keys(components).forEach(item => {
    Vue.component(item, components[item]);
})

const Router = new router({
    routes: [
        ...routes,
        {
            path: '/',
            component: () => import('@/views/pages/activity/index'),
            children
        },
    ]
})
//路由拦截器
// Router.beforeEach((to, from, next) => {
//     console.log(to);
//     // to.name 为无需使用token的页面 可追加
//     if (localStorage.getItem('token') || to.name="") {
//         next();
//     } else {
//         // next({
//         //     name: ""
//         // })
//     }
// })

export default Router;