<template>
  <div class="head_img_box">
    <van-nav-bar title="" left-arrow @click-left="$router.go(-1)" fixed :border='false' />
    <div class="img_box">
      <img :src="getImage(img)" alt="">
    </div>
    <div class="btn_box">
      <button class="defined" @click="define()">設為大頭貼照</button>
    </div>
  </div>
</template>

<script>
import { getImage } from "@/utils/upload";

export default {
  name: "head_img",
	created () {
		window.scrollTo(0, 0)
		this.num = JSON.parse(localStorage.getItem("taigu_useres"));
		this.get_glade()
	},
  data() {
    return {
    	num: {},
	    glader: {},
	    img: this.$route.query.item
    }
  },
  methods: {
    getImage,
	  // 获取佳丽个人信息
	  get_glade() {
		  this.$get({
			  url: '/api/Act_glade/GetGladeByvipid',
			  data: {
				  actid: this.num.actid, // 活动id
				  vipid: this.num.idDecrypt
			  },
			  success: (res) => {
				  this.glader = res.data
			  },
			  tip: () => {}
		  })
	  },
    define() {
	    let data = {
		    actID: this.glader.actID,
		    vipId: this.num.idDecrypt,
		    vipName: this.glader.vipName,
		    gladeName: this.glader.gladeName,
		    contact: this.glader.contact,
		    email: this.glader.email,
		    auditStatus: this.glader.auditStatus,
		    auditReason: this.glader.auditReason,
		    createDate: this.glader.createDate,
		    createID: this.glader.createID,
		    creator: this.glader.creator,
		    fbUrl: this.glader.fbUrl,
		    id: this.num.gladeid,
		    igUrl: this.glader.igUrl,
		    individualResume: this.glader.individualResume,
		    modifier: this.glader.modifier,
		    modifyDate: this.glader.modifyDate,
		    modifyID: this.glader.modifyID,
		    phoneNum: this.glader.phoneNum,
		    photo1: this.img,
		    photo2: this.glader.photo2,
		    remark: this.glader.remark,
		    sort: this.glader.sort,
		    status: this.glader.status,
		    vidio: this.glader.vidio,
		    isFinal: this.glader.isFinal,
		    tagsCount: this.glader.tagsCount,
		    votesCount: this.glader.votesCount,
		    company: this.glader.company,
		    sharesCount: this.glader.sharesCount,
		    tagsSort: this.glader.tagsSort,
		    votesSort: this.glader.votesSort,
		    sortNO: this.glader.sortNO,
	    };
	    this.$post({
		    url: `/api/Act_glade/SaveGlade`,
		    raw: true,
		    data,
		    success: (res) => {
			    this.$toast(res.message)
			    setTimeout(()=>{
				    this.$router.back()
			    },1500)
		    },
		    tip: () => {},
	    });
    }
  }
}
</script>

<style scoped lang="less">
.box{}

.head_img_box {

  .img_box {
    margin-top: 162px;
    margin-bottom: 244px;

    img {
      width: 750px;
      height: 750px;
    }
  }

  .btn_box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .defined {
      width: 550px;
      height: 92px;
      background: linear-gradient(180deg, #4E9CDA 0%, #29487B 100%);
      border-radius: 46px 46px 46px 46px;
      font-size: 32px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 38px;
    }
  }
}
</style>
