var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxs"},[_c('div',{staticClass:"list"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"","immediate-check":false},on:{"load":_vm.onload},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"check"}),_c('div',{staticClass:"cell"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"cells",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                path: 'personalfile',
                query: {
                  actid: item.actID,
                  vipid: item.vipId,
                  gladeid: item.id,
                }})}}},[_c('div',{staticClass:"cell_item"},[_c('div',{staticClass:"cell_flex"},[_c('div',{staticClass:"cell_head"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImage(item.photo1)),expression:"getImage(item.photo1)"}]})]),_c('div',{staticClass:"cell_name"},[_c('div',{staticClass:"item_name"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.gladeName))]),(item.isNewState == 1)?_c('div',{staticClass:"name_logo"},[_vm._v("NEW")]):_vm._e()]),_c('div',{staticClass:"item_num"},[_c('div',{staticClass:"num_img"},[_c('img',{attrs:{"src":require("@/assets/img/shoucang.png")}})]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(item.votesCount))])])])]),_c('div',{staticClass:"cell_zan",on:{"click":function($event){$event.stopPropagation();return _vm.Vote(item)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.isvote(item.id) ? _vm.votesimg : _vm.no_votes),expression:"isvote(item.id) ? votesimg : no_votes"}],attrs:{"alt":""}})])])])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }