import Vue from "vue";
import App from "./App";
import router from "./router/index";
import http from "./request/http";
import "vant/lib/index.css";
import "./assets/css/reset.css";
// 引入视频组件
import VueCoreVideoPlayer from "vue-core-video-player";
Vue.use(VueCoreVideoPlayer, {
  lang: "zh-CN",
});
Vue.use(http);
Vue.config.productionTip = false;
//复制粘贴
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
//常规验证码 运算验证码 滑动验证码 拼图验证码 选字验证码
import Verify from "vue2-verify";
Vue.component("Verify", Verify);
import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_WS_PATH,
  options: {
    autoConnect: false,
  }
}))
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

//        _==/          i     i          \==_
//      /XX/            |\___/|            \XX\
//    /XXXX\            |XXXXX|            /XXXX\
//   |XXXXXX\_         _XXXXXXX_         _/XXXXXX|
//  XXXXXXXXXXXxxxxxxxXXXXXXXXXXXxxxxxxxXXXXXXXXXXX
// |XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX|
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// |XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX|
//  XXXXXX/^^^^"\XXXXXXXXXXXXXXXXXXXXX/^^^^^\XXXXXX
//   |XXX|       \XXX/^^\XXXXX/^^\XXX/       |XXX|
//     \XX\       \X/    \XXX/    \X/       /XX/
//        "\       "      \X/      "      /"
